/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  gridFilteredSortedRowIdsSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarProps,
  ToolbarPropsOverrides,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { Box, Button, Menu, MenuItem, MenuProps, styled, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch } from 'react-redux';
import { setDateRangeFilter, setDateRangeOptionId } from '../ducks/muiDataGridSlice';
import { DateRange as DateRangeType } from '../types/DateRange';
import { getCurrentMonthDateRange, getLastDaysDateRange, getPriorMonthDateRange } from '../utils/dateUtils';
import { useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';
import { DateRangeDialog } from './DateRangeDialog';
import { FilterMenuItems } from '../constants/FilterMenuItems';
import { I18nKeys } from '../constants/I18nKeys';

interface Props {
  count?: number;
  anchorEl: HTMLButtonElement | undefined;
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

const useStyles = makeStyles({
  container: {
    background: '#F5F5F5',
    padding: '16px !important',
  },
  dateFilterButton: {
    marginEnd: '44px',
  },
  text: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textTransform: 'none',
  },
  notChecked: {
    width: '24px',
    height: '24px',
    marginEnd: '20px',
  },
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={2}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    minWidth: 202,
    boxShadow: '0px 1px 10px 0px #00000033 0px 4px 5px 0px #0000001F 0px 2px 4px 0px #00000024',
    '& .MuiMenu-list': {
      padding: '8px 0',
    },
    '& .MuiMenuItem-root': {
      padding: '8px 24px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#000000DE',
    },
  },
}));

type DateRangeOption = {
  key: FilterMenuItems;
  label: string;
  filterMessage: string;
  dateRangeFilter?: DateRangeType;
};

const dateRangeOptions: DateRangeOption[] = [
  {
    key: FilterMenuItems.Days45,
    label: I18nKeys.FilterBy45Days,
    filterMessage: I18nKeys.OrderFilterLast45Days,
    dateRangeFilter: getLastDaysDateRange(45),
  },
  {
    key: FilterMenuItems.Days90,
    label: I18nKeys.FilterBy90Days,
    filterMessage: I18nKeys.OrderFilterLast90Days,
    dateRangeFilter: getLastDaysDateRange(90),
  },
  {
    key: FilterMenuItems.MonthCurrent,
    label: I18nKeys.FilterByThisMonth,
    filterMessage: I18nKeys.OrderFilterThisMonth,
    dateRangeFilter: getCurrentMonthDateRange(),
  },
  {
    key: FilterMenuItems.MonthLast,
    label: I18nKeys.FilterByLastMonth,
    filterMessage: I18nKeys.OrderFilterLastMonth,
    dateRangeFilter: getPriorMonthDateRange(),
  },
  { key: FilterMenuItems.Custom, label: I18nKeys.FilterByCustomDates, filterMessage: I18nKeys.OrderFilterCustom },
];

export const MUIDataGridToolbar: React.FC<GridToolbarProps & ToolbarPropsOverrides & Props> = ({
  setFilterButtonEl,
  count,
  anchorEl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiRef = useGridApiContext();
  const { dateRangeOptionId, dateRangeFilter } = useAppSelector((state: AppState) => state.muiDataGrid);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = React.useState(false);

  const [dateRangeFilterOpen, setDateRangeFilterOpen] = useState(false);
  const filteredCount = gridFilteredSortedRowIdsSelector(apiRef)?.length;

  const handleClose = (id = 0) => {
    const option = dateRangeOptions[id];
    if (option.dateRangeFilter) {
      dispatch(setDateRangeFilter({ dateRangeFilter: option.dateRangeFilter }));
      dispatch(setDateRangeOptionId(id));
    }
    if (option.key === FilterMenuItems.Custom) {
      setDateRangeDialogOpen(true);
    }

    setDateRangeFilterOpen(false);
  };

  const handleDateRangeDialogCancel = (): void => {
    setDateRangeDialogOpen(false);
  };

  const handleDateRangeDialogApply = (newDateRange: DateRangeType): void => {
    dispatch(setDateRangeFilter({ dateRangeFilter: newDateRange }));
    setDateRangeDialogOpen(false);
    dispatch(setDateRangeOptionId(dateRangeOptions.length - 1));
  };

  return (
    <GridToolbarContainer className={classes.container}>
      <Button
        ref={setFilterButtonEl}
        className={classes.dateFilterButton}
        color="buttonGray"
        aria-controls={dateRangeFilterOpen ? 'date-range-customized-button' : undefined}
        aria-haspopup="true"
        aria-expanded={dateRangeFilterOpen ? 'true' : undefined}
        onClick={() => {
          setDateRangeFilterOpen(!dateRangeFilterOpen);
        }}
        startIcon={<DateRange sx={{ width: '24px', height: '24px' }} />}
      >
        <Typography className={classes.text}>
          {t(I18nKeys.MUIDataGridFilterMessage, {
            optionLabel: t(dateRangeOptions[dateRangeOptionId].filterMessage),
            startDate: dateRangeFilter.startDate.format('MM/DD/YYYY'),
            endDate: dateRangeFilter.endDate.format('MM/DD/YYYY'),
            context: FilterMenuItems[dateRangeOptions[dateRangeOptionId].key],
          })}
          {}
        </Typography>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'date-range-customized-button',
        }}
        anchorEl={anchorEl}
        open={dateRangeFilterOpen}
        onClose={() => {
          handleClose();
        }}
      >
        {dateRangeOptions.map((option, id) => (
          <MenuItem
            key={option.key}
            onClick={() => {
              handleClose(id);
            }}
            disableRipple
          >
            <Box className={classes.notChecked}>
              {id === dateRangeOptionId ? <CheckIcon sx={{ width: '24px', height: '24px' }} /> : <div />}
            </Box>
            {t(option.label)}
          </MenuItem>
        ))}
      </StyledMenu>

      <GridToolbarFilterButton
        slotProps={{
          button: {
            color: 'buttonGray',
            classes: { text: classes.text },
          },
          tooltip: {
            disableFocusListener: true,
            disableHoverListener: true,
            disableTouchListener: true,
          },
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Typography className={classes.text}>
        {filteredCount != null && filteredCount !== count
          ? t(I18nKeys.MUIDataGridFilterCountFiltered, {
              filteredCount,
              count,
            })
          : t(I18nKeys.MUIDataGridFilterCountNoFilter, {
              count,
            })}
      </Typography>

      <DateRangeDialog
        open={dateRangeDialogOpen}
        onApply={handleDateRangeDialogApply}
        onCancel={handleDateRangeDialogCancel}
        dateRange={dateRangeFilter}
      />
    </GridToolbarContainer>
  );
};
