import { AnyAction, ThunkDispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { Auth } from '@aws-amplify/auth';
import { navigate } from 'hookrouter';
import { DateUtils } from '@aws-amplify/core';
import { toast } from 'react-toastify';
import { AppState } from '../types/AppState';
import { config as configuration } from '../config/config';
import {
  changeGroup,
  clearErrors,
  fetchUserPreferences,
  fetchUserPreferencesComplete,
  impersonationFailed,
  initiateImpersonation,
  oauthSignIn,
  recordUserEvent,
  saveUserPreferences,
  saveUserPreferencesComplete,
  setCurrentUserAuthProvider,
  setCurrentUserAuthStatus,
  setCurrentUserData,
  setErrors,
  setUserPreferences,
  signOut,
  signedIn,
  signedInFailed,
  signedInSuccess,
} from '../ducks/currentUserSlice';
import {
  openTour,
  setDefaultClientId,
  setMenuStatus,
  setPaymentIntegrationStatus,
  setSelectedClientId,
  setTourSteps,
} from '../ducks/viewerSlice';
import { Dealer } from '../types/Dealer';
import { GroupData } from '../types/Group';
import { groupApi } from '../services/groupApi';
import { SystemGroups } from '../constants/SystemGroups';
import { getGroupIdFromUrl } from '../utils/clientDataUtils';
import { AppRoutes } from '../constants/AppRoutes';
import { LocalStorage } from '../constants/LocalStorage';
import { AuthStatus } from '../constants/AuthStatus';
import { SALESVIEW } from '../constants/App';
import { settingApi } from '../services/settingApi';
import { openSalesViewWhatsNewSuccess } from '../ducks/settings';
import { openDialog } from '../ducks/dialogSlice';
import { getTourSteps } from '../utils/viewerUtils';
import { Dialogs } from '../constants/Dialogs';
import { userApi } from '../services/userApi';
import { MUIDataGridPreferences, unknownUser } from '../types/User';
import { unknownGroup } from '../constants/Group';
import { setFocusedMenuItem } from '../ducks/menu';
import { MenuItems } from '../constants/MenuItems';
import { setQueryParam } from '../utils/urlUtils';
import { mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { clientDataApi } from '../services/clientDataApi';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { getConfigWithVendor } from '../utils/configuratorUtils';
import { dealerApi } from '../services/dealerApi';
import { Configurator, ConfiguratorEnabledOnProps } from '../types/Configurator';
import { referenceConfigurator } from '../constants/Configurator';
import { resetSearch } from '../ducks/search';
import { portalService } from '../services/portalService';
import { SessionStorage } from '../constants/SessionStorage';
import { defaultErrorMessage } from '../constants/Error';
import { extractErrorProps } from '../utils/errorUtils';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { UserPreference } from '../constants/User';
import { EventName } from '../analytics/AnalyticsTypes';
import { unknownWhatsNew } from '../constants/WhatsNew';
import { integrationApi } from '../services/integrationApi';
import { IntegrationStatus } from '../constants/IntegrationStatus';
import { MenuStatus } from '../constants/Viewer';
import { updateDataGridState } from '../ducks/muiDataGridSlice';
import { isIdeaRoomUser } from '../utils/userUtils';

/* global localStorage */

const clearUserLocalStorageItems = () => {
  [
    LocalStorage.LastPricingBaseMergeCommit,
    LocalStorage.LastPricingComponentMergeCommit,
    LocalStorage.LastPricingSizeBasedMergeCommit,
    LocalStorage.HaveShownPricingBaseDeletingPriceDialog,
  ].forEach((key) => localStorage.removeItem(key));
};

export const userListener = createListenerMiddleware<AppState>();

const fetchCurrentUserData = async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>): Promise<void> => {
  const availableDealers: Dealer[] = [];
  try {
    // API gateway will only permit requests if the clock skew is less than 5 minutes.
    // Once logged in adjust for any offset in the users clock so that API requests do
    // not fail. The symptoms of a failed request is a CORS InvalidSignatureException.
    await Auth.currentAuthenticatedUser().then((u) => {
      DateUtils.setClockOffset(-u.signInUserSession.clockDrift * 1000);
    });

    dispatch(setCurrentUserAuthStatus(AuthStatus.SignedIn));

    const { groups } = await dispatch(
      groupApi.endpoints.getUserGroups.initiate(undefined, {
        forceRefetch: true,
        subscriptionOptions: { refetchOnFocus: false },
      }),
    ).unwrap();

    // If user is not in any groups, throw an error
    if (!groups) {
      throw new Error('User not associated with any groups.');
    }

    // Check that the user is a member of one of the groups or in the IdeaRoom group, if so,
    // use that group.
    const groupIdIsValid = (groupId: string | undefined): boolean =>
      groupId !== undefined &&
      (groups.some((group: GroupData) => group.groupId === groupId) ||
        groups.some((group: GroupData) => group.groupId === SystemGroups.IdeaRoom));

    // Get the group from the uri path. /portal/data/:groupId/...
    const pathGroupId = window.location.pathname.startsWith(AppRoutes.ClientData) ? getGroupIdFromUrl() : undefined;
    // Get the previously selected group from local storage.
    const previouslySelectedGroupId = localStorage.getItem(LocalStorage.SelectedGroupId);

    let selectedGroupId: string | undefined;
    if (pathGroupId && groupIdIsValid(pathGroupId)) {
      selectedGroupId = pathGroupId;
    } else if (previouslySelectedGroupId && groupIdIsValid(previouslySelectedGroupId)) {
      selectedGroupId = previouslySelectedGroupId;
    } else {
      // If the user is not a member of the group, default to the first group
      // in their list of groups. If the user is in the IdeaRoom group, default to it.
      selectedGroupId = groups.some((group: GroupData) => group.groupId === SystemGroups.IdeaRoom)
        ? SystemGroups.IdeaRoom
        : groups[0].groupId || '';
    }
    if (selectedGroupId) {
      localStorage.setItem(LocalStorage.SelectedGroupId, selectedGroupId);
    }

    // Get the stored whatsNewKey, default to 0, then get the latest 3 What's New records
    // Check to see if there are any that have been unseen based on the whatsNewKey
    // If so, update the stored whatsNewKey and open the dialog with the unseen What's New
    const whatsNewKey = parseInt(localStorage.getItem(LocalStorage.WhatsNewKey) || '0', 10) || 0;

    const [salesviewWhatsNew = [unknownWhatsNew], clientSalesviewWhatsNew = [unknownWhatsNew]] = await Promise.all(
      [
        {
          clientId: SALESVIEW,
          count: 3,
        },
        {
          clientId: `${SALESVIEW}-${selectedGroupId}`,
          count: 3,
        },
      ].map((args) =>
        dispatch(
          settingApi.endpoints.getLatestWhatsNew.initiate(args, {
            forceRefetch: true,
            subscriptionOptions: { refetchOnFocus: false },
          }) as any,
        ).unwrap(),
      ),
    );

    const whatsNew = [...salesviewWhatsNew, ...clientSalesviewWhatsNew];
    const largestId = Math.max(...whatsNew.map((wn) => wn.id));
    const unseenWhatsNew = largestId >= whatsNewKey ? whatsNew.filter((what) => what.id > whatsNewKey) : [];
    if (unseenWhatsNew.length) {
      localStorage.setItem(LocalStorage.WhatsNewKey, largestId.toString());
      dispatch(openSalesViewWhatsNewSuccess(unseenWhatsNew));
      dispatch(openDialog({ dialog: Dialogs.WhatsNewSalesView }));
    }

    const tourIndex = localStorage.getItem(LocalStorage.TourIndex) || undefined;
    if (tourIndex === undefined) {
      dispatch(setTourSteps(getTourSteps(groups)));
      dispatch(openTour());
    }

    let user;
    let group;

    try {
      ({ user = unknownUser, group = unknownGroup } = await dispatch(
        userApi.endpoints.getCurrentUserData.initiate(
          {
            groupId: selectedGroupId,
          },
          { forceRefetch: true, subscriptionOptions: { refetchOnFocus: false } },
        ),
      ).unwrap());
    } catch (e) {
      localStorage.removeItem(LocalStorage.SelectedGroupId);
      throw e;
    }

    dispatch(fetchUserPreferences());

    const currentPath = window.location.pathname;
    if (!currentPath || !currentPath.startsWith(AppRoutes.Portal)) {
      if (selectedGroupId === SystemGroups.IdeaRoom) {
        dispatch(setFocusedMenuItem(MenuItems.Groups));
        navigate(AppRoutes.Groups);
      } else {
        dispatch(setFocusedMenuItem(MenuItems.Leads));
        navigate(AppRoutes.Leads);

        // Set a uuid query param if it exists in session storage
        setQueryParam('uuid');
      }
    } else {
      // Selects the menu item based on the current URL path
      let focusedMenuItem;
      if (currentPath.startsWith(AppRoutes.Groups) || currentPath.replace(/\/$/, '') === AppRoutes.Portal) {
        if (selectedGroupId === SystemGroups.IdeaRoom) {
          focusedMenuItem = MenuItems.Groups;
        } else {
          dispatch(setFocusedMenuItem(MenuItems.Leads));
          navigate(AppRoutes.Leads);
        }
      } else if (currentPath.startsWith(AppRoutes.Leads)) {
        if (selectedGroupId === SystemGroups.IdeaRoom) {
          dispatch(setFocusedMenuItem(MenuItems.Groups));
          navigate(AppRoutes.Groups);
        } else {
          focusedMenuItem = MenuItems.Leads;
        }
      } else if (currentPath.startsWith(AppRoutes.Pricing)) {
        if (selectedGroupId === SystemGroups.IdeaRoom) {
          dispatch(setFocusedMenuItem(MenuItems.Groups));
          navigate(AppRoutes.Groups);
        } else {
          focusedMenuItem = MenuItems.Pricing;
        }
      } else if (currentPath.startsWith(AppRoutes.Users)) {
        if (selectedGroupId === SystemGroups.IdeaRoom) {
          dispatch(setFocusedMenuItem(MenuItems.Groups));
          navigate(AppRoutes.Groups);
        } else {
          focusedMenuItem = MenuItems.Users;
        }
      } else if (currentPath.startsWith(AppRoutes.Dealers)) {
        if (selectedGroupId === SystemGroups.IdeaRoom) {
          dispatch(setFocusedMenuItem(MenuItems.Groups));
          navigate(AppRoutes.Groups);
        } else {
          focusedMenuItem = MenuItems.Dealers;
        }
      } else if (currentPath.startsWith(AppRoutes.Sites)) {
        if (selectedGroupId === SystemGroups.IdeaRoom) {
          dispatch(setFocusedMenuItem(MenuItems.Groups));
          navigate(AppRoutes.Groups);
        } else {
          focusedMenuItem = MenuItems.Sites;
        }
      }
      if (focusedMenuItem !== undefined) {
        dispatch(setFocusedMenuItem(focusedMenuItem));
      }
    }

    let defaultClientId;
    if (group) {
      const { configurators = [] } = group;
      const [firstConfig] = configurators;
      defaultClientId = mapConfiguratorToClientId(firstConfig);

      const configuratorsWithVendor = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const config of configurators) {
        const clientId = mapConfiguratorToClientId(config);

        // eslint-disable-next-line no-await-in-loop
        const vendor = await dispatch(
          clientDataApi.endpoints.getVendorData.initiate(
            { clientId },
            { forceRefetch: true, subscriptionOptions: { refetchOnFocus: false } },
          ),
        ).unwrap();

        // eslint-disable-next-line no-await-in-loop
        const updatedConfig = (await getConfigWithVendor(config, vendor)) as Configurator;
        configuratorsWithVendor.push(updatedConfig);
      }

      // Sort them alphabetically
      configuratorsWithVendor.sort((a: Configurator, b: Configurator) => ((a.name || '') > (b.name || '') ? 1 : -1));
      const clientIds = configuratorsWithVendor.map((configurator: Configurator) =>
        mapConfiguratorToClientId(configurator),
      );

      const { dealers = [] } = await dispatch(
        dealerApi.endpoints.getDealersByClientIds.initiate(
          {
            clientIds,
            groupId: selectedGroupId,
          },
          {
            forceRefetch: true,
            subscriptionOptions: { refetchOnFocus: false },
          },
        ),
      ).unwrap();

      availableDealers.push(
        ...dealers.filter((dealer) => dealer).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      );

      if (group.groupId === 'IdeaRoom') {
        configuratorsWithVendor.push(referenceConfigurator);
        dispatch(setSelectedClientId(referenceConfigurator.clientId));
        dispatch(setDefaultClientId(referenceConfigurator.clientId));
      } else if (isIdeaRoomUser(user)) {
        dispatch(
          integrationApi.endpoints.getPaymentIntegration.initiate(
            { groupId: group.groupId, clientIds },
            {
              forceRefetch: true,
              subscriptionOptions: { refetchOnFocus: false },
            },
          ),
        )
          .unwrap()
          .then((integrationStatus) => {
            dispatch(setPaymentIntegrationStatus(integrationStatus));
          })
          .catch(() => {
            dispatch(setPaymentIntegrationStatus({ status: IntegrationStatus.DISCONNECTED }));
          });
      }

      group = { ...group, configurators: configuratorsWithVendor };
    }

    const { groups: allGroups = [] } = await dispatch(
      groupApi.endpoints.getUserGroups.initiate(undefined, {
        forceRefetch: true,
        subscriptionOptions: { refetchOnFocus: false },
      }),
    ).unwrap();

    dispatch(setCurrentUserData({ user, group, groups: allGroups, availableDealers }));
    dispatch(setSelectedClientId(defaultClientId));
    dispatch(setDefaultClientId(defaultClientId));
    dispatch(signedInSuccess({ username: user.username, groupId: group.groupId }));
  } catch (error) {
    const { message } = error as Error;
    toast.error(message);

    dispatch(signedInFailed());
    dispatch(signOut());
  }
};

userListener.startListening({
  actionCreator: signedIn,
  effect: async (action, { dispatch }) => {
    fetchCurrentUserData(dispatch);
  },
});

userListener.startListening({
  actionCreator: changeGroup,
  effect: async (action, { dispatch, getState }) => {
    const {
      currentUser: { impersonation: { user: impersonatedUser } = {} },
    } = getState();
    try {
      const groupId = action.payload;
      const availableDealers: Dealer[] = [];

      localStorage.setItem(LocalStorage.SelectedGroupId, groupId);

      let user;
      let group;

      try {
        ({ user = unknownUser, group = unknownGroup } = await dispatch(
          userApi.endpoints.getCurrentUserData.initiate(
            {
              groupId,
            },
            { forceRefetch: !!impersonatedUser, subscriptionOptions: { refetchOnFocus: false } },
          ),
        ).unwrap());
      } catch (e) {
        localStorage.removeItem(LocalStorage.SelectedGroupId);
        throw e;
      }

      let defaultClientId;
      if (group) {
        // If on the client data page, update the GroupId in the URL
        if (window.location.pathname.startsWith(AppRoutes.ClientData)) {
          navigate(`${AppRoutes.ClientData}/${groupId}`);
        }

        const { configurators = [] } = group;
        const [firstConfig] = configurators;
        defaultClientId = mapConfiguratorToClientId(firstConfig);

        const configuratorsWithVendor = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const config of configurators) {
          const clientId = mapConfiguratorToClientId(config);

          // eslint-disable-next-line no-await-in-loop
          const vendor = await dispatch(
            clientDataApi.endpoints.getVendorData.initiate(
              { clientId },
              { forceRefetch: !!impersonatedUser, subscriptionOptions: { refetchOnFocus: false } },
            ),
          ).unwrap();

          // eslint-disable-next-line no-await-in-loop
          const updatedConfig = await getConfigWithVendor(config, vendor);
          configuratorsWithVendor.push(updatedConfig);
        }

        // Sort them alphabetically
        configuratorsWithVendor.sort((a: Configurator, b: Configurator) => ((a.name || '') > (b.name || '') ? 1 : -1));
        const clientIds = configuratorsWithVendor.map((configurator: Configurator) =>
          mapConfiguratorToClientId(configurator),
        );

        const { dealers = [] } = await dispatch(
          dealerApi.endpoints.getDealersByClientIds.initiate(
            {
              clientIds,
              groupId,
            },
            {
              forceRefetch: !!impersonatedUser,
              subscriptionOptions: { refetchOnFocus: false },
            },
          ),
        ).unwrap();

        availableDealers.push(
          ...dealers
            .filter((dealer) => dealer)
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
        );

        if (group.groupId === 'IdeaRoom') {
          configuratorsWithVendor.push(referenceConfigurator);
          dispatch(setSelectedClientId(referenceConfigurator.clientId));
          dispatch(setDefaultClientId(referenceConfigurator.clientId));
        } else if (isIdeaRoomUser(user)) {
          dispatch(
            integrationApi.endpoints.getPaymentIntegration.initiate(
              { groupId: group.groupId, clientIds },
              {
                forceRefetch: true,
                subscriptionOptions: { refetchOnFocus: false },
              },
            ),
          )
            .unwrap()
            .then((integrationStatus) => {
              dispatch(setPaymentIntegrationStatus(integrationStatus));
            })
            .catch(() => {
              dispatch(setPaymentIntegrationStatus({ status: IntegrationStatus.DISCONNECTED }));
            });
        }

        group = { ...group, configurators: configuratorsWithVendor };
      }

      const { groups = [] } = await dispatch(
        groupApi.endpoints.getUserGroups.initiate(undefined, {
          forceRefetch: !!impersonatedUser,
          subscriptionOptions: { refetchOnFocus: false },
        }),
      ).unwrap();

      dispatch(resetSearch());

      const currentPath = window.location.pathname;
      if (!currentPath || !currentPath.startsWith(AppRoutes.Portal)) {
        if (groupId === SystemGroups.IdeaRoom) {
          dispatch(setFocusedMenuItem(MenuItems.Groups));
          navigate(AppRoutes.Groups);
        } else {
          dispatch(setFocusedMenuItem(MenuItems.Leads));
          navigate(AppRoutes.Leads);
        }
      } else {
        // Selects the menu item based on the current URL path
        let focusedMenuItem;
        if (currentPath.startsWith(AppRoutes.Groups)) {
          if (groupId === SystemGroups.IdeaRoom) {
            focusedMenuItem = MenuItems.Groups;
          } else {
            dispatch(setFocusedMenuItem(MenuItems.Leads));
            navigate(AppRoutes.Leads);
          }
        } else if (currentPath.startsWith(AppRoutes.Leads)) {
          if (groupId === SystemGroups.IdeaRoom) {
            dispatch(setFocusedMenuItem(MenuItems.Groups));
            navigate(AppRoutes.Groups);
          } else {
            focusedMenuItem = MenuItems.Leads;
          }
        } else if (currentPath.startsWith(AppRoutes.Pricing)) {
          if (groupId === SystemGroups.IdeaRoom) {
            dispatch(setFocusedMenuItem(MenuItems.Groups));
            navigate(AppRoutes.Groups);
          } else if (
            group.configurators.some((config: Configurator) =>
              getEnabledOnProperty(config.vendorData, ConfiguratorEnabledOnProps.PricingEnabled, true),
            )
          ) {
            focusedMenuItem = MenuItems.Pricing;
          } else {
            dispatch(setFocusedMenuItem(MenuItems.Leads));
            navigate(AppRoutes.Leads);
          }
        } else if (currentPath.startsWith(AppRoutes.Users)) {
          if (groupId === SystemGroups.IdeaRoom) {
            dispatch(setFocusedMenuItem(MenuItems.Groups));
            navigate(AppRoutes.Groups);
          } else {
            focusedMenuItem = MenuItems.Users;
          }
        } else if (currentPath.startsWith(AppRoutes.Dealers)) {
          if (groupId === SystemGroups.IdeaRoom) {
            dispatch(setFocusedMenuItem(MenuItems.Groups));
            navigate(AppRoutes.Groups);
          } else {
            focusedMenuItem = MenuItems.Dealers;
          }
        } else if (currentPath.startsWith(AppRoutes.Sites)) {
          if (groupId === SystemGroups.IdeaRoom) {
            dispatch(setFocusedMenuItem(MenuItems.Groups));
            navigate(AppRoutes.Groups);
          } else {
            focusedMenuItem = MenuItems.Sites;
          }
        }
        if (focusedMenuItem !== undefined) {
          dispatch(setFocusedMenuItem(focusedMenuItem));
        }
      }

      dispatch(setCurrentUserData({ user, group, groups, availableDealers }));
      dispatch(setSelectedClientId(defaultClientId));
      dispatch(setDefaultClientId(defaultClientId));
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  },
});

userListener.startListening({
  actionCreator: signOut,
  effect: async () => {
    try {
      await Auth.signOut();
      clearUserLocalStorageItems();

      const currentPath = window.location.pathname;
      if (!currentPath.includes(AppRoutes.Sso)) {
        navigate(AppRoutes.SignIn);
      }
    } catch (error) {
      const { message } = error as Error;
      toast.error(message);
    }
  },
});

userListener.startListening({
  actionCreator: oauthSignIn,
  effect: async (action, { dispatch, getState }) => {
    try {
      dispatch(clearErrors());
      const state = getState();
      const {
        currentUser: { authProvider },
      } = state;

      if (!authProvider || authProvider.length < 1) {
        const message = 'SSO provider cannot be blank';
        dispatch(setErrors({ provider: message }));
        throw new Error(message);
      } else {
        const { provider: validProvider } = (await portalService.getValidSsoProvider(authProvider)) as {
          provider: string;
        };

        if (!validProvider) {
          dispatch(setCurrentUserAuthProvider());
          navigate(AppRoutes.Sso);
        } else {
          await Auth.federatedSignIn({ customProvider: validProvider });
        }
      }
    } catch (error) {
      const { message } = error as Error;
      console.error(`error:`, message); // eslint-disable-line no-console
    }
  },
});

userListener.startListening({
  actionCreator: initiateImpersonation,
  effect: async (action, { dispatch, getState }) => {
    try {
      const userToImpersonate = action.payload;
      const { username: usernameToImpersonate } = userToImpersonate;

      const state = getState();

      const {
        currentUser: { user: { username = '' } = {} },
      } = state;

      const session =
        (await dispatch(
          userApi.endpoints.initiateImpersonation.initiate({ username, usernameToImpersonate }),
        ).unwrap()) || '';

      // Store session to be used in validation
      sessionStorage.setItem(SessionStorage.ImpersonationSession, session);

      dispatch(openDialog({ dialog: Dialogs.Impersonation }));
    } catch (error) {
      let { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      if (errorMessage === 'User does not exist in user pool') errorMessage = i18n.t(I18nKeys.UserMissingFromUserPool);

      dispatch(impersonationFailed());
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: fetchUserPreferences,
  effect: async (action, { dispatch }) => {
    try {
      let { preferences } =
        (await dispatch(
          userApi.endpoints.getCurrentUserPreferences.initiate(undefined, {
            forceRefetch: true,
            subscriptionOptions: { refetchOnFocus: false },
          }),
        ).unwrap()) || {};

      if (!preferences[UserPreference.ClientDataPreferences]) {
        // Fetch previous client data preferences from local storage
        preferences = {
          ...preferences,
          [UserPreference.ClientDataPreferences]: JSON.parse(
            localStorage.getItem(LocalStorage.ClientDataPreferences) || '{}',
          ),
        };
      }

      if (preferences) {
        dispatch(setMenuStatus(preferences?.[UserPreference.ProfilePreferences]?.menuStatus || MenuStatus.Expanded));
        dispatch(setUserPreferences(preferences));
      }
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    } finally {
      dispatch(fetchUserPreferencesComplete());
    }
  },
});

userListener.startListening({
  actionCreator: saveUserPreferences,
  effect: async (action, { dispatch, getState }) => {
    try {
      const { userPreference, preferences } = action.payload;
      const state = getState();
      const { currentUser: { preferences: existingPreferences = {} } = {} } = state;

      if (!Object.values(UserPreference).includes(userPreference)) throw new Error('Invalid user preference key');

      if (preferences) {
        const newPreferences = { ...existingPreferences, [userPreference]: preferences };

        const { preferences: updatedPreferences = {} } =
          (await dispatch(userApi.endpoints.saveUserPreferences.initiate({ preferences: newPreferences })).unwrap()) ||
          {};

        dispatch(setUserPreferences(updatedPreferences));
      }
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    } finally {
      dispatch(saveUserPreferencesComplete());
    }
  },
});

const recordEvent = async (dispatch: ThunkDispatch<AppState, unknown, AnyAction>, action: AnyAction) => {
  try {
    const { type } = action;
    const { groupId, clientId, eventCategory, eventType: actionEventType } = action.payload;
    let eventType = actionEventType || type;
    if (configuration.environment.STAGE === 'production') {
      switch (type) {
        case signedInSuccess.type:
          eventType = EventName.SignIn; // eslint-disable-line no-param-reassign
          break;
        case changeGroup.type:
          eventType = EventName.Change; // eslint-disable-line no-param-reassign
          break;
        default:
          break;
      }

      await dispatch(userApi.endpoints.addUserEvent.initiate({ groupId, clientId, eventCategory, eventType }));
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    toast.error(errorMessage);
  }
};

userListener.startListening({
  actionCreator: signedInSuccess,
  effect: async (action, { dispatch }) => {
    try {
      await recordEvent(dispatch, action);
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: changeGroup,
  effect: async (action, { dispatch }) => {
    try {
      const groupId = action.payload;
      await recordEvent(dispatch, { ...action, payload: { groupId } });
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: recordUserEvent,
  effect: async (action, { dispatch }) => {
    try {
      await recordEvent(dispatch, action);
    } catch (error) {
      const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
      toast.error(errorMessage);
    }
  },
});

userListener.startListening({
  actionCreator: updateDataGridState,
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const {
      currentUser: { preferences: { [UserPreference.MUIDataGrid]: muiDataGridPreferences = {} } = {} },
    } = state;
    const { dataGridKey } = action.payload;

    let { columns, pinnedColumns } = muiDataGridPreferences[dataGridKey] || {};
    if ('pinnedColumns' in action.payload) {
      ({ pinnedColumns } = action.payload);
    }
    if ('columns' in action.payload) {
      ({ columns } = action.payload);
    }

    const prefs: MUIDataGridPreferences = {
      ...muiDataGridPreferences,
      [dataGridKey]: {
        pinnedColumns,
        columns,
      },
    };

    dispatch(saveUserPreferences({ userPreference: UserPreference.MUIDataGrid, preferences: prefs }));
  },
});
