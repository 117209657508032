import { DialogActions, DialogContent } from '@mui/material';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog, closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LoadingButton } from './LoadingButton';
import { unknownUser } from '../types/User';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { CELL_METADATA_TABLE } from '../constants/ClientData';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { useDeleteBranchMutation } from '../services/clientDataApi';
import { setChangeSummaryOpen, setClientDataBranch } from '../ducks/clientDataSlice';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { unknownGroup } from '../constants/Group';

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  firstButton: {
    marginRight: 'auto',
  },
});

export const ClientDataRevertBranchDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user = unknownUser } = useAppSelector((state) => state?.currentUser);
  const { clientDataBranch, clientId, clientDataType } = useAppSelector((state) => state?.clientData);
  const { activeBranches } = useClientDataRepo({ useBranches: true });
  const { group: { groupId } = unknownGroup } = useAppSelector((state) => state?.currentUser);
  const [deleteBranch, { isLoading }] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });
  const { authors = [], changedTables = [] } =
    activeBranches.find((metadata) => metadata.branchType === clientDataBranch) || {};

  const setBranchLabelFunc = (branch?: ClientDataBranch): string =>
    branch ? branch.charAt(0).toUpperCase() + branch.slice(1) : '';
  const [branchLabel, setBranchLabel] = React.useState<string>(setBranchLabelFunc(clientDataBranch));
  const displayTablesAsNumber = changedTables.length > 3;
  const displayAuthorsAsNumber = authors.length > 3;
  const localizedAuthors = authors.map((authorName) =>
    authorName === user.name ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : authorName,
  );
  const tableLabels = changedTables
    .filter((table) => table !== CELL_METADATA_TABLE)
    .map((table) => compoundCaseToTitleCase(table));
  const metadataChanges = changedTables.includes(CELL_METADATA_TABLE);

  useEffect(() => {
    if (!isLoading) {
      // Wait to set the branch label until the branch has been deleted
      setBranchLabel(setBranchLabelFunc(clientDataBranch));
    }
  }, [clientDataBranch, isLoading]);

  return (
    <Dialog dialogKey={Dialogs.ClientDataRevertBranch} maxWidth="sm">
      <DialogContent>
        <Trans
          i18nKey={I18nKeys.ClientDataRevertBranchDialogText as string}
          values={{
            branch: branchLabel,
            authors: displayAuthorsAsNumber
              ? t(I18nKeys.ClientDataRevertBranchDialogTextAuthorsMultiple, { count: authors.length })
              : t(I18nKeys.ClientDataRevertBranchDialogTextAuthors, {
                  authors: localizedAuthors,
                  count: authors.length,
                }),
            changedTables: displayTablesAsNumber
              ? t(I18nKeys.ClientDataRevertBranchDialogTextChangedTablesMultiple, { count: tableLabels.length })
              : t(I18nKeys.ClientDataRevertBranchDialogTextChangedTables, {
                  changedTables: tableLabels,
                  count: tableLabels.length,
                }),
            metadataChanges: metadataChanges ? t(I18nKeys.ClientDataRevertBranchDialogTextMetadataChanges) : '',
          }}
          components={{ bold: <strong /> }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.firstButton}
          color="primary"
          onClick={() => {
            dispatch(closeDialogFunc());
            dispatch(setChangeSummaryOpen(true));
          }}
        >
          {t(I18nKeys.ClientDataRevertBranchDialogChangesButton)}
        </Button>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <LoadingButton
          onClick={(): void => {
            if (clientDataBranch) {
              deleteBranch({
                dataType: clientDataType,
                branch: clientDataBranch,
                clientId,
                groupId,
              })
                .unwrap()
                .then(() => {
                  dispatch(closeDialog());
                });
              dispatch(setClientDataBranch(ClientDataBranch.Main));
            }
          }}
          loading={isLoading}
          color="primary"
        >
          {t(I18nKeys.ClientDataRevertBranchDialogRevertButton)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
