import React, { useMemo, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CellClassParams,
  Column,
  GetContextMenuItemsParams,
  MenuItemDef,
  ProcessDataFromClipboardParams,
  RowNode,
  ValueSetterParams,
  CellFocusedEvent,
  PostProcessPopupParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { ContentCopy } from '@mui/icons-material';
import { Button, Icon, Theme, Typography, alpha } from '@mui/material';
import { PriceColumn } from '@idearoom/types';
import addPlusIcon from '../images/addPlusIcon.svg';
import deletePrice from '../images/deletePrice.svg';
import { DataGrid } from './DataGrid';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CellMetadata, ClientDataTableRowDiff } from '../types/ClientData';
import {
  getAllUniqueDimensionValues,
  getRowData,
  updateCellMetadata,
  getClientDataRowIdsFromSelectedRange,
  processDataFromClipboard,
  updateValues,
  getAvailablePricesPerRegionDiff,
  getRowSpanHeader,
  getYAxisDimensionColumnDef,
  updateYAxisDimensionColumnWidth,
  updatePriceColumnWidths,
  formatPriceWithDecimal,
  getListGridViewColDefs,
  getXAxisDimensionColDefs,
  getPricingSheetDimensions,
  askToDeleteRows,
} from '../utils/pricingSheetUtils';
import { arePriceValuesDifferent } from '../utils/pricingUtils';
import { BaseTableData } from '../types/DataGrid';
import { I18nKeys } from '../constants/I18nKeys';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import {
  SHEDVIEW,
  mapClientAndDataTypeToClientDataId,
  mapClientAndPricingSheetToUndoStackId,
  getConfiguratorFromClientId,
} from '../utils/clientIdUtils';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { getCellRangeInfo, getRowDiffColumnToFromValues, hasCellMetadataProperty } from '../utils/clientDataUtils';
import { CellMetadataProperty, DoltDiffType } from '../constants/ClientData';
import noteSrc from '../images/note.svg';
import deleteNoteSrc from '../images/deleteNote.svg';
import { setCellNote } from '../ducks/clientDataSlice';
import { onGridKeyboardShortcut } from '../utils/keyboardShortcutHandlerUtils';
import { setHighlightedCell } from '../ducks/pricingSlice';
import { clearSelections } from '../utils/selectionUtils';
import { Region } from '../types/Region';
import { GridViewType } from '../constants/GridViewType';
import { PricingSheet as PricingSheetType } from '../types/PricingSheet';
import { defaultColumn } from '../constants/PricingBase';
import { capitalizeFirstLetter } from '../utils/stringUtils';
import { DefaultPricingColumnFieldNames, PricingSheetDimension, PricingTab } from '../constants/Pricing';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';

const useStyles = makeStyles<Theme, { gridViewType: GridViewType }>((styles) => ({
  root: {
    display: 'flex',
  },
  grid: {
    width: 'auto',
    height: '100%',
    '& .ag-cell': {
      padding: '0 3px',
      borderTop: '1px transparent',
      textAlign: 'right',
    },
    '& .ag-header-cell': {
      paddingLeft: '7px',
      paddingRight: '6px',
      backgroundColor: 'D0D0D0',
    },
    '& .ag-header-cell-label': {
      justifyContent: 'right',
      fontSize: '13px',
      lineHeight: '20px',
    },
    '& .ag-grid-x-dimension-header-column': {
      headerColumnSeparatorDisplay: 'none',
      backgroundColor: 'var(--ag-header-background-color)',
      borderLeft: '1px solid var(--ag-row-border-color)',
    },
    ' & .ag-header-row .ag-focus-managed:first-child': {
      borderLeft: '1px solid var(--ag-row-border-color)',
      '&::after': {
        display: ({ gridViewType }) => (gridViewType === GridViewType.List ? 'none' : ''),
      },
    },
  },
  gridModifiedCell: {
    backgroundColor: '#E4A76733 !important',
  },
  gridRegionSameAsPriceCell: {
    color: '#00000061 !important',
    fontWeight: '400 !important',
  },
  noteHandle: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderBottom: '7px solid transparent',
      borderRight: '7px solid #4994EC',
      right: '-1px',
      top: '0px',
    },
  },
  missingPriceHandle: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderBottom: '7px solid transparent',
      borderRight: '7px solid #F44336',
      right: '-1px',
      top: '0px',
    },
  },
  addSizeButtonText: {
    fontWeight: '500',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    lineHeight: '16px',
  },
  addSizeButtonIcon: {
    width: '24px',
    height: '24px',
  },
  addSizeButton: {
    margin: '0px 24px',
    height: 'fit-content',
    borderRadius: '4px',
    justifyContent: 'flex-start',
    color: alpha('#000', 0.87),
    background: alpha('#000', 0.08),
    padding: '8px 18px',
  },
  hiddenPrice: {
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.60)',
  },
  highlightCell: {
    backgroundColor: 'var(--ag-row-hover-color)',
  },
}));

interface ContextMenuParams {
  metadata: CellMetadata[];
  columns: string[];
  client: string;
  dataType: ClientDataType;
  clientDataId: string;
  table: string;
  branch?: ClientDataBranch;
}

export interface PriceRow extends BaseTableData {
  [key: string]: any;
}

export const PricingSheetGrid: React.FC<{
  gridViewType: GridViewType;
  regions?: Region[];
  hasDefaultRegion?: boolean;
  pricingSheet: PricingSheetType | undefined;
  cellMetadata: CellMetadata[];
  diffs: { table: string; changes: ClientDataTableRowDiff[] }[];
  branch?: ClientDataBranch;
  showAddSize?: boolean;
}> = ({ gridViewType, regions, hasDefaultRegion = false, pricingSheet, cellMetadata, branch, diffs, showAddSize }) => {
  const { t } = useTranslation();
  const classes = useStyles({ gridViewType });
  const dispatch = useAppDispatch();

  const {
    viewer: { currency = 'USD', useMetricUnits, selectedPricingTabId: pricingTab = '' },
    pricing: { highlightedCell },
    clientData: { clientId },
  } = useAppSelector((state) => state);
  const configurator = getConfiguratorFromClientId(clientId);
  const pricingSheetDimensions = getPricingSheetDimensions(pricingTab as PricingTab, gridViewType);
  const { x, y } = pricingSheetDimensions;

  const { id, prices = [] } = pricingSheet || {};
  const { priceColumn = undefined, regionKey = undefined } = regions?.length === 1 ? regions[0] : {};
  const useDecimalFormat = formatPriceWithDecimal(pricingSheet, regions, priceColumn);

  const getPriceColumn = useCallback(
    (colId: string | undefined): PriceColumn =>
      (colId && Object.values(PriceColumn).includes(colId as PriceColumn) ? (colId as PriceColumn) : priceColumn) ||
      PriceColumn.price,
    [priceColumn],
  );

  const unitTranslation = t(useMetricUnits ? I18nKeys.PricingSheetMetricUnit : I18nKeys.PricingSheetImperialUnit);
  const clientDataTableId = mapClientAndPricingSheetToUndoStackId(clientId, id);

  let xAxisColumns: string[] = [];
  if (pricingSheet) {
    if (gridViewType === GridViewType.Grid) {
      xAxisColumns = getAllUniqueDimensionValues(x, prices);
    } else {
      xAxisColumns = regions?.map((r) => r.priceColumn) || [PriceColumn.price];
    }
  }

  const isCellHidden = (params: any) => {
    const {
      data,
      colDef,
      context: { regionKey: region },
    } = params;
    const { colId = '' } = colDef;
    const { data: rowData } = data;
    const cellRegionKey =
      gridViewType === GridViewType.List ? regions?.find(({ priceColumn: col }) => colId === col)?.regionKey : region;
    if (rowData?.hidden[cellRegionKey]) {
      return false;
    }
    return true;
  };

  const contextMenuParams: ContextMenuParams = {
    metadata: cellMetadata,
    columns: xAxisColumns,
    client: clientId,
    dataType: ClientDataType.Supplier,
    clientDataId: mapClientAndDataTypeToClientDataId(clientId, ClientDataType.Supplier),
    table: clientDataTableId,
    branch,
  };

  const rootRef = React.useRef<HTMLDivElement>(null);
  const gridRef = React.useRef<AgGridReact>(null);
  const tableMenuButtonRef = React.useRef<HTMLDivElement>(null);
  const { api: gridApi } = gridRef.current || {};
  const suppressRowTransform = true;

  const getContextMenuItems = useCallback(
    (
      { api, node, column }: GetContextMenuItemsParams,
      { metadata, branch: b }: ContextMenuParams,
    ): (string | MenuItemDef)[] => {
      const cellRanges = api.getCellRanges();
      const { rowCount, columns: selectedColumns } = getCellRangeInfo(cellRanges);

      const cellSelected = node && column;
      const rangeSelected = cellSelected && (rowCount > 1 || selectedColumns.length > 1);
      let data: any;
      let columnId: string;
      let cellNoteExists = false;
      let rangeNoteExists = false;
      let clientDataRowId: string;
      if (cellSelected) {
        ({ data } = (node || {}) as RowNode);
        columnId = (column as Column).getColId();
        clientDataRowId = data[columnId].clientDataRowId;
        cellNoteExists = hasCellMetadataProperty(
          metadata,
          clientDataRowId,
          getPriceColumn(columnId),
          CellMetadataProperty.Note,
        );
      }
      const cellsFromSelectedRange = getClientDataRowIdsFromSelectedRange(api);
      if (rangeSelected) {
        columnId = (column as Column).getColId();
        rangeNoteExists =
          cellNoteExists ||
          cellsFromSelectedRange.some((r) =>
            selectedColumns.some((c) => hasCellMetadataProperty(metadata, r as string, c, CellMetadataProperty.Note)),
          );
      }

      const cellsSelectedCount = gridViewType === GridViewType.Grid ? cellsFromSelectedRange.length : rowCount;

      const result: (string | MenuItemDef)[] = [
        ...(cellSelected && !column?.getColId().includes(y)
          ? [
              {
                name: `Copy`,
                action: () => api.copyToClipboard(),
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
                shortcut: '⌘C',
              },
              {
                name: `Copy with ${capitalizeFirstLetter(x)} Headers`,
                action: () => api.copyToClipboard({ includeHeaders: true }),
                icon: renderToStaticMarkup(<ContentCopy className="ag-icon" />),
                shortcut: '⇧⌘C',
              },
              'separator',
              ...(configurator?.key === SHEDVIEW
                ? [
                    {
                      name: t(I18nKeys.PricingBaseDeleteSize, { count: cellsSelectedCount }),
                      action: () => {
                        if (pricingSheet && gridApi) {
                          askToDeleteRows(
                            cellsFromSelectedRange,
                            pricingSheet,
                            clientDataTableId,
                            dispatch,
                            t,
                            gridApi,
                          );
                        }
                      },
                      icon: renderToStaticMarkup(<img alt="delete price" src={deletePrice} />),
                      shortcut: '⌘-',
                    },
                    'separator',
                  ]
                : []),
              ...(!column?.getColId().includes(y)
                ? [
                    {
                      name: `${cellNoteExists ? 'Edit' : 'Add'} Note${rangeSelected ? 's' : ''}`,
                      action: () => {
                        dispatch(
                          // Selected node should be first to be edited
                          setCellNote({
                            rowIds: getClientDataRowIdsFromSelectedRange(api),
                            colIds: [getPriceColumn(columnId)],
                          }),
                        );
                        dispatch(openDialog({ dialog: Dialogs.PricingBaseNote }));
                      },
                      icon: renderToStaticMarkup(<img alt="Note" src={noteSrc} className="ag-icon" />),
                      shortcut: '⇧F2',
                    },
                    ...(cellNoteExists || rangeNoteExists
                      ? [
                          {
                            name: `Delete Note${rangeSelected ? 's' : ''}`,
                            action: () => {
                              if (b) {
                                updateCellMetadata(
                                  clientDataTableId,
                                  getClientDataRowIdsFromSelectedRange(api).flatMap((r) => ({
                                    cellsMetadata: metadata,
                                    colId: getPriceColumn(columnId),
                                    rowId: r,
                                    metadataProperty: CellMetadataProperty.Note,
                                    value: '',
                                  })),
                                  dispatch,
                                );
                              }
                            },
                            icon: renderToStaticMarkup(
                              <img alt="Delete Note" src={deleteNoteSrc} className="ag-icon" />,
                            ),
                          },
                        ]
                      : []),
                  ]
                : []),
            ]
          : []),

        'export',
      ];

      return result;
    },
    [clientDataTableId, dispatch, getPriceColumn, pricingSheet, t],
  );

  const postProcessPopup = (params: PostProcessPopupParams) => {
    const { type, column, ePopup } = params;
    if (type !== 'contextMenu') {
      return;
    }
    let viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    // subtract 64px from the viewHeight to account for the Preview/Publish bar
    viewHeight -= 64;
    const columnId = column ? column.getColId() : undefined;
    if (columnId && ePopup) {
      // Adjust by 20% for submenu that goes lower than the popup
      const popUpBottomPos = ePopup.offsetHeight * 1.2 + ePopup.offsetTop;
      if (popUpBottomPos > viewHeight) {
        ePopup.style.top = `${viewHeight - ePopup.offsetHeight * 1.2}px`;
      }
    }
  };

  const rowDataPrices = useMemo(() => {
    if (pricingSheet) {
      const rowData = getRowData(prices, unitTranslation, gridViewType, pricingSheetDimensions, priceColumn, regions);
      updateYAxisDimensionColumnWidth(y, gridApi);
      return rowData;
    }
    return [];
  }, [pricingSheet, prices, gridApi, priceColumn, unitTranslation, gridViewType, pricingSheetDimensions, regions, y]);

  const getCellClass = useCallback(
    (cellClassParam: CellClassParams<PriceRow>) => {
      const classList: string[] = [];

      const { colId = '' } = cellClassParam.colDef;
      const {
        data,
        context: { regionKey: region },
      } = cellClassParam;
      const { cellMetadata: cellMetadataFromContext = [] } = cellClassParam.context;

      const { [colId]: cellData } = (data || {}) as PriceRow;

      if (
        (
          [...Object.values(DefaultPricingColumnFieldNames), ...Object.values(PricingSheetDimension)] as string[]
        ).includes(colId)
      )
        return classList;

      if (cellData) {
        const { clientDataRowId } = cellData;
        const rowDiff = diffs
          .flatMap(({ changes }) => changes)
          .find((c) => c[ClientDataFixedColumns.RowId] === clientDataRowId);
        let hasMissingPriceIndicator = false;

        if (rowDiff) {
          const columnDiff = data ? getRowDiffColumnToFromValues(rowDiff, getPriceColumn(colId)) : undefined;

          if (
            rowDiff.diffType === DoltDiffType.Added ||
            (columnDiff && arePriceValuesDifferent(columnDiff.from, columnDiff.to))
          ) {
            classList.push(classes.gridModifiedCell);
          }

          const dataPrice = pricingSheet?.prices?.find((p) => p.rowId === cellData.clientDataRowId);
          hasMissingPriceIndicator =
            gridViewType === GridViewType.Grid &&
            rowDiff.diffType === DoltDiffType.Added &&
            getPriceColumn(colId) !== PriceColumn.price &&
            !cellData[getPriceColumn(colId)] &&
            !!dataPrice?.[PriceColumn.price];
          if (hasMissingPriceIndicator) {
            classList.push(classes.missingPriceHandle);
          }
        }

        if (clientDataRowId && colId) {
          const hasNote = hasCellMetadataProperty(
            cellMetadataFromContext,
            clientDataRowId,
            getPriceColumn(colId),
            CellMetadataProperty.Note,
          );
          if (hasNote && !hasMissingPriceIndicator) {
            classList.push(classes.noteHandle);
          }
        }

        const cellRegionKey =
          gridViewType === GridViewType.List
            ? regions?.find(({ priceColumn: col }) => colId === col)?.regionKey
            : region;
        if (cellData?.hidden[cellRegionKey]) {
          classList.push(classes.hiddenPrice);
        }
      }

      if (hasDefaultRegion && getPriceColumn(colId) !== PriceColumn.price && cellData && classList.length === 0) {
        const dataPrice = prices?.find((p) => p.rowId === cellData.clientDataRowId);
        const priceCol = getPriceColumn(colId);
        if (dataPrice && !arePriceValuesDifferent(cellData[priceCol], dataPrice[PriceColumn.price])) {
          classList.push(classes.gridRegionSameAsPriceCell);
        }
      }

      return classList;
    },
    [
      regions,
      hasDefaultRegion,
      prices,
      pricingSheet,
      gridViewType,
      classes.gridModifiedCell,
      getPriceColumn,
      diffs,
      classes.gridRegionSameAsPriceCell,
      classes.noteHandle,
      classes.missingPriceHandle,
      classes.hiddenPrice,
    ],
  );

  const [groupingCellUpdates, setGroupingCellUpdates] = React.useState(false);
  const [groupedCellUpdates, setGroupedCellUpdates] = React.useState<any[]>([]);

  const onGroupUpdateStart = () => {
    setGroupingCellUpdates(true);
    setGroupedCellUpdates([]);
  };

  const onGroupUpdateComplete = () => {
    updateValues(clientDataTableId, groupedCellUpdates, cellMetadata, dispatch);
    setGroupingCellUpdates(false);
    setGroupedCellUpdates([]);
  };

  const onGroupUpdateEdit = useCallback(
    ({ oldValue, newValue, data, column, colDef }: ValueSetterParams): boolean => {
      groupedCellUpdates.push({
        priceColumn: getPriceColumn(column.getColId()),
        oldValue,
        newValue,
        data,
        column: column.getColId(),
        colDef,
      });
      return true;
    },
    [getPriceColumn, groupedCellUpdates],
  );

  // Define the default column configuration with usememo because the default config doesn't change once initialized
  const selectedTableColumns = useMemo(() => {
    if (pricingSheet) {
      const colDefs = [getRowSpanHeader(rowDataPrices.length), getYAxisDimensionColumnDef(y, gridViewType)];

      if (gridViewType === GridViewType.List && regions) {
        const availablePricePerRegion = getAvailablePricesPerRegionDiff(configurator?.key, new Map(), regions, prices);

        colDefs.push(
          ...getListGridViewColDefs(
            clientDataTableId,
            pricingSheet,
            regions || [],
            dispatch,
            t,
            getCellClass,
            useDecimalFormat,
            groupingCellUpdates,
            availablePricePerRegion,
            onGroupUpdateEdit,
            currency,
            hasDefaultRegion,
          ),
        );
      } else {
        colDefs.push(
          ...getXAxisDimensionColDefs(
            clientDataTableId,
            pricingSheet,
            regions || [],
            priceColumn || PriceColumn.price,
            x,
            getAllUniqueDimensionValues(x, prices),
            dispatch,
            t,
            getCellClass,
            unitTranslation,
            useDecimalFormat,
            groupingCellUpdates,
            onGroupUpdateEdit,
            currency,
          ),
        );
      }

      return colDefs;
    }

    return [];
  }, [
    configurator,
    clientDataTableId,
    rowDataPrices,
    pricingSheet,
    prices,
    unitTranslation,
    dispatch,
    t,
    currency,
    getCellClass,
    useDecimalFormat,
    groupingCellUpdates,
    onGroupUpdateEdit,
    priceColumn,
    gridViewType,
    regions,
    hasDefaultRegion,
    x,
    y,
  ]);

  useEffect(() => {
    // Must refresh cells when data diff or cell metadata changes due to custom styling
    // FIXME: refresh only cells that changed in datadiff and cell metadata
    gridApi?.refreshCells({ force: true, suppressFlash: true });
    if (gridViewType === GridViewType.List) {
      gridApi?.refreshHeader();
    }
  }, [gridViewType, pricingSheet, gridApi, cellMetadata]);

  useMemo(() => {
    const { region, colId, rowId } = highlightedCell || {};
    const focusedCell = gridApi?.getFocusedCell();
    const focusedRowId = focusedCell ? gridApi?.getDisplayedRowAtIndex(focusedCell.rowIndex)?.id : undefined;
    const focusedColId = focusedCell?.column?.getColId();

    if (pricingSheet && region === getPriceColumn(focusedColId) && colId && rowId && !focusedCell) {
      setTimeout(() => {
        gridApi?.setFocusedCell(Number(rowId), colId);
      }, 1000);
    }
    if (region === getPriceColumn(focusedColId) && focusedCell && (colId !== focusedColId || rowId !== focusedRowId)) {
      setHighlightedCell({ region, colId: focusedColId, rowId: focusedRowId });
    }
  }, [gridApi, highlightedCell, getPriceColumn, pricingSheet]);

  useMemo(() => {
    if (gridApi && priceColumn && highlightedCell?.region !== priceColumn) {
      clearSelections(gridApi);
    }
  }, [gridApi, priceColumn, highlightedCell]);

  useEffect(() => {
    // reset the column definitions for a pricing sheet change and decimal formatting
    gridApi?.setGridOption('columnDefs', selectedTableColumns);
    updatePriceColumnWidths(currency, gridApi, useDecimalFormat);
  }, [selectedTableColumns, currency, gridApi, useDecimalFormat]);

  useEffect(() => {
    const setCellFlashColor = undefined;
    const openTableMenu = () => tableMenuButtonRef.current;
    const onKeyDown = (e: KeyboardEvent) => {
      if (
        (!highlightedCell && priceColumn === PriceColumn.price) ||
        highlightedCell?.region === priceColumn ||
        gridViewType === GridViewType.List
      ) {
        onGridKeyboardShortcut(
          e,
          {
            dispatch,
            openTableMenu,
            gridApi,
            setCellFlashColor,
            dataColumn: priceColumn,
            pricingSheet,
            configurator,
          },
          clientDataTableId,
          false,
          true,
        );
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [
    configurator,
    tableMenuButtonRef,
    clientDataTableId,
    highlightedCell,
    dispatch,
    gridApi,
    getPriceColumn,
    priceColumn,
    gridViewType,
    pricingSheet,
  ]);

  const handleOpenAddSize = () => {
    dispatch(openDialog({ dialog: Dialogs.PricingBaseAddSize }));
  };

  return (
    <div className={classes.root} ref={rootRef}>
      <DataGrid
        className={classes.grid}
        gridRef={gridRef}
        data={rowDataPrices} // Price Data for Rows
        context={{
          regionKey,
        }}
        cellMetadata={cellMetadata}
        domLayout={pricingSheet ? 'print' : 'normal'} // Auto-size grid width and height to fit all contents also does not use scrollbars.
        enableCellChangeFlash={false}
        getContextMenuItems={(params: GetContextMenuItemsParams) => getContextMenuItems(params, contextMenuParams)}
        getCellClass={getCellClass}
        initialHeaderHeight={30}
        initialRowHeight={30}
        onModelUpdated={({ api }) => updateYAxisDimensionColumnWidth(y, api)}
        onGroupUpdateStart={onGroupUpdateStart}
        onGroupUpdateComplete={onGroupUpdateComplete}
        selectedTable={clientDataTableId}
        defaultColDefinition={defaultColumn}
        selectedTableColumns={selectedTableColumns}
        readOnly={false}
        suppressRowTransform={suppressRowTransform}
        onCellFocused={({ rowIndex, column, api }: CellFocusedEvent) => {
          const colId = typeof column === 'string' ? column : column?.getColId();
          const rowId = rowIndex ? api.getDisplayedRowAtIndex(rowIndex)?.id : undefined;
          dispatch(setHighlightedCell({ region: getPriceColumn(colId), rowId, colId }));
        }}
        processDataFromClipboard={(params: ProcessDataFromClipboardParams) => {
          if (!isCellHidden(params)) {
            return null;
          }

          return processDataFromClipboard(params, {
            clientDataTableId,
            gridViewType,
            regions,
            getPriceColumn,
            dispatch,
          });
        }}
        popupParent={document.querySelector('body') || rootRef.current || undefined}
        postProcessPopup={(params: PostProcessPopupParams) => postProcessPopup(params)}
      />

      {showAddSize && (
        <div>
          <Button
            className={classes.addSizeButton}
            startIcon={
              <Icon className={classes.addSizeButtonIcon}>
                <img alt="add size" src={addPlusIcon} />
              </Icon>
            }
            onClick={handleOpenAddSize}
          >
            <Typography className={classes.addSizeButtonText}>{t(I18nKeys.PricingBaseAddSizeButtonTitle)}</Typography>
          </Button>
        </div>
      )}
    </div>
  );
};
