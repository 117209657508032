/* eslint-disable react/jsx-props-no-spreading */
import React, { MutableRefObject, useLayoutEffect, useState } from 'react';
import {
  DataGridPremiumProps,
  gridClasses,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnsPanel,
  GridRowsProp,
  GridSlots,
  DataGridPremium,
  GridRowIdGetter,
  GridLocaleText,
} from '@mui/x-data-grid-premium';
import { deDE, enUS, esES, frFR, nbNO, nlNL } from '@mui/x-data-grid-premium/locales';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, useTheme } from '@mui/material';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { usePath } from 'hookrouter';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useDispatch } from 'react-redux';
import { MUIDataGridToolbar } from './MUIDataGridToolbar';
import { AppRoutes } from '../constants/AppRoutes';
import { setFilterModel, updateDataGridState } from '../ducks/muiDataGridSlice';
import { useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { i18n } from '../i18n';
import { UserPreference } from '../constants/User';

const useStyles = makeStyles(() => ({
  grid: {
    border: 0,
    background: 'white',
    [`& .${gridClasses.columnHeaderTitle}`]: {
      fontWeight: '700',
      fontSize: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      whiteSpace: 'normal',
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    [`& .${gridClasses.cell}`]: {
      fontWeight: '400',
      fontSize: '14px',
      overflow: 'hidden',
      lineHeight: '20px !important',
      textOverflow: 'ellipsis',
      color: 'rgba(0, 0, 0, 0.87)',
      alignItems: 'center',
      justifyContent: 'start',
      padding: '12px',
    },
    [`& .${gridClasses.footerContainer}`]: {
      background: '#F5F5F5',
    },
    '& .MuiTablePagination-selectLabel': {
      display: 'none !important',
    },
    '& .MuiInputBase-root': {
      display: 'none !important',
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none',
    },
  },
  columnsPanel: {
    [`& .MuiInputBase-root`]: {
      minHeight: '48px',
    },
    [`& .MuiOutlinedInput-input`]: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    [`& .MuiFormControlLabel-root`]: {
      minHeight: '34px',
    },
    [`& .MuiCheckbox-root`]: {
      marginLeft: '8px',
      marginRight: '20px',
    },
    [`& .MuiTypography-root`]: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    [`& .MuiButton-text`]: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.75px',
    },
  },
  columnMenu: {
    [`& hr:first-of-type`]: {
      display: 'none',
    },
    [`& .MuiTypography-root`]: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
}));

const RemoveAllIcon = () => {
  const theme = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 3V4H4V6H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V6H20V4H15V3H9ZM7 6H17V19H7V6ZM9 8V17H11V8H9ZM13 8V17H15V8H13Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

const PinLeftIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8171 13.8171L18.5532 6.29959L19.4929 6.64161L20.177 4.76222L10.78 1.34202L10.096 3.22141L11.0357 3.56343L8.29952 11.081L5.73609 12.2763L5.05205 14.1557L9.93845 15.9342L7.88633 21.5724L9.38984 22.1196L11.442 16.4814L16.3284 18.2599L17.0124 16.3806L15.8171 13.8171ZM8.36723 13.234L9.90528 12.5168L12.9151 4.24747L16.6738 5.61555L13.6641 13.8848L14.3813 15.4229L8.36723 13.234Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

const PinRightIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8171 11.081L13.081 3.56343L14.0207 3.22141L13.3366 1.34203L3.93969 4.76223L4.62373 6.64161L5.56343 6.29959L8.29959 13.8171L7.10424 16.3806L7.78828 18.2599L12.6747 16.4814L14.7268 22.1196L16.2303 21.5724L14.1782 15.9342L19.0646 14.1557L18.3806 12.2763L15.8171 11.081ZM9.73538 15.4229L10.4526 13.8848L7.44281 5.61555L11.2016 4.24747L14.2114 12.5168L15.7494 13.234L9.73538 15.4229Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

const CustomColumnsPanel = (props: React.ComponentProps<typeof GridColumnsPanel>) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <GridColumnsPanel {...props} className={classes.columnsPanel} sx={{ minHeight: 0 }} />
    </Box>
  );
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  const classes = useStyles();
  return (
    <GridColumnMenu
      {...props}
      className={classes.columnMenu}
      slotProps={{
        columnMenuFilterItem: {
          displayOrder: 1,
        },
        columnMenuSortItem: {
          displayOrder: 0,
        },
      }}
    />
  );
};

const getDataGridKey = (path: string): string => {
  if (path.startsWith(AppRoutes.Payments)) {
    return 'MUIDatGridPayments';
  }
  return `MUIDatGrid`;
};

export const MuiDataGrid: React.FC<{
  loading: boolean;
  rows: GridRowsProp;
  columns: GridColDef[];
  apiRef: MutableRefObject<GridApiPremium>;
  getRowId: GridRowIdGetter<any> | undefined;
}> = ({ loading, rows, columns, apiRef, getRowId }) => {
  const path = usePath();
  const dataGridKey = getDataGridKey(path);
  const [stateRestored, setStateRestored] = useState(false);

  const {
    muiDataGrid: { filterModel },
    currentUser: { preferences: { [UserPreference.MUIDataGrid]: preferences = {} } = {} },
  } = useAppSelector((state) => state);
  const { columns: columnsState = {}, pinnedColumns } = preferences[dataGridKey] || {};

  useLayoutEffect(() => {
    if (apiRef.current && !stateRestored) {
      apiRef.current.restoreState({ pinnedColumns, columns: columnsState });
      setStateRestored(true);
    }
  }, [apiRef, pinnedColumns, columnsState, setStateRestored, stateRestored]);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | undefined>(undefined);
  const { t } = useTranslation();

  const onFilterModelChange = React.useCallback<NonNullable<DataGridPremiumProps['onFilterModelChange']>>(
    (newFilterModel, details) => {
      // we are receiving a clear state when opening the page with a previous saved filter, only change the save if there is a reason too.
      if (details.reason) {
        dispatch(setFilterModel(newFilterModel));
      }
    },
    [dispatch],
  );

  const updateDataGridPinnedColumnsCallback = React.useCallback<
    NonNullable<DataGridPremiumProps['onPinnedColumnsChange']>
  >(
    (newPinnedColumns) => {
      dispatch(
        updateDataGridState({
          dataGridKey,
          pinnedColumns: newPinnedColumns,
        }),
      );
      // need to call restoreState because on unpin the column's order was not respecting their last order.
      apiRef.current.restoreState({ pinnedColumns: newPinnedColumns, columns: columnsState });
    },
    [dispatch, apiRef, dataGridKey, columnsState],
  );

  const updateDataGridColumnsStateCallback = React.useCallback(() => {
    const { columns: newColumns } = apiRef.current.exportState();
    dispatch(updateDataGridState({ dataGridKey, columns: newColumns }));
  }, [dispatch, apiRef, dataGridKey]);

  const localeText = React.useCallback(() => {
    let defaultLocaleText: Partial<GridLocaleText> = {};
    switch (i18n.language) {
      case 'de': {
        defaultLocaleText = deDE.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'es': {
        defaultLocaleText = esES.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'fr': {
        defaultLocaleText = frFR.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'nl': {
        defaultLocaleText = nlNL.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      case 'no': {
        defaultLocaleText = nbNO.components.MuiDataGrid.defaultProps.localeText;
        break;
      }
      default:
        defaultLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;
    }

    return {
      ...defaultLocaleText,
      toolbarFilters: t(I18nKeys.MUIDataGridFilter),
      filterPanelColumns: t(I18nKeys.MUIDataGridColumn),
      columnMenuUnsort: t(I18nKeys.MUIDataGridUnsort),
      pinToLeft: t(I18nKeys.MUIDataGridPinToLeft),
      pinToRight: t(I18nKeys.MUIDataGridPinToRight),
      columnMenuShowColumns: t(I18nKeys.MUIDataGridShowColumns),
      columnMenuManageColumns: t(I18nKeys.MUIDataGridManageColumns),
      columnMenuSortAsc: (colDef: GridColDef) => {
        switch (colDef.type) {
          case 'number': {
            return t(I18nKeys.MUIDataGridSortNumberAsc);
          }
          case 'dateTime':
            return t(I18nKeys.MUIDataGridSortDateAsc);
          default:
            return t(I18nKeys.MUIDataGridSortAsc);
        }
      },
      columnMenuSortDesc: (colDef: GridColDef) => {
        switch (colDef.type) {
          case 'number': {
            return t(I18nKeys.MUIDataGridSortNumberDesc);
          }
          case 'dateTime':
            return t(I18nKeys.MUIDataGridSortDateDesc);
          default:
            return t(I18nKeys.MUIDataGridSortDesc);
        }
      },
    };
  }, [t]);

  return (
    <DataGridPremium
      className={classes.grid}
      showColumnVerticalBorder={false}
      hideFooterSelectedRowCount
      pagination
      getRowId={getRowId}
      rowSelection={false}
      autoHeight
      apiRef={apiRef}
      loading={loading}
      rows={rows}
      columns={columns}
      filterModel={filterModel}
      disableRowGrouping
      disableAggregation
      onPinnedColumnsChange={updateDataGridPinnedColumnsCallback}
      onColumnOrderChange={updateDataGridColumnsStateCallback}
      onColumnVisibilityModelChange={updateDataGridColumnsStateCallback}
      onColumnWidthChange={updateDataGridColumnsStateCallback}
      onFilterModelChange={onFilterModelChange}
      slots={{
        toolbar: MUIDataGridToolbar as GridSlots['toolbar'],
        filterPanelRemoveAllIcon: RemoveAllIcon,
        columnsPanel: CustomColumnsPanel,
        columnMenu: CustomColumnMenu,
        columnMenuFilterIcon: FilterListOutlinedIcon,
        columnMenuHideIcon: VisibilityOffOutlinedIcon,
        columnMenuManageColumnsIcon: ViewColumnOutlinedIcon,
        columnMenuPinLeftIcon: PinLeftIcon,
        columnMenuPinRightIcon: PinRightIcon,
      }}
      slotProps={{
        filterPanel: {
          // Display columns by ascending alphabetical order
          columnsSort: 'asc',
          filterFormProps: {
            logicOperatorInputProps: {
              variant: 'filled',
            },
            columnInputProps: {
              variant: 'filled',
            },
            operatorInputProps: {
              variant: 'filled',
            },
            valueInputProps: {
              InputComponentProps: {
                variant: 'filled',
              },
            },
            deleteIconProps: {
              sx: {
                '& .MuiSvgIcon-root': { width: '24px', height: '24px' },
              },
            },
          },
          sx: {
            // Customize inputs using css selectors
            '& .MuiButton-text': {
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0.75px',
            },
            '& .MuiButtonBase-root': {
              padding: '0px',
            },
            '& .MuiDataGrid-filterForm': { padding: '16px 16px 16px 12px', alignItems: 'center' },
            '& .MuiDataGrid-panelFooter': { padding: '16px' },
            '& .MuiDataGrid-filterFormColumnInput': { mr: '5px', width: 150 },
            '& .MuiDataGrid-filterFormOperatorInput': { mr: '5px', width: 120 },
            '& .MuiDataGrid-filterFormValueInput': { width: 179 },
          },
        },
        panel: {
          anchorEl: filterButtonEl,
        },
        toolbar: {
          anchorEl: filterButtonEl,
          setFilterButtonEl,
          count: rows.length,
        },
      }}
      localeText={localeText()}
    />
  );
};
