export enum I18nKeys {
  AddButton = 'add-button',
  Adjustments = 'adjustments',
  AllowedHTMLDialogTitle = 'allowed-html-dialog-title',
  AllowedHTMLDialogContent = 'allowed-html-dialog-content',
  AuthenticationProviderEmail = 'authentication-provider-email',
  AuthenticationProviderCognito = 'authentication-provider-cognito',
  AuthenticationProviderOIDC = 'authentication-provider-oidc',
  ApplyDataUpdatesButton = 'apply-data-updates-button',
  Base = 'base',
  BulkUserFormAllDealersLabel = 'bulk-user-form-all-dealers-label',
  BulkUserFormDealerLabel = 'bulk-user-form-dealer-label',
  BulkUserFormUsernamesLabel = 'bulk-user-form-usernames-label',
  CheckingSheetId = 'checking-sheet-id',
  ClearFiltersButton = 'clear-filters-button',
  ClientDataAddNoteDialogTitle = 'client-data-add-note-dialog-title',
  ClientDataClientBranchDisabledActionTooltip = 'client-data-client-branch-disabled-action-tooltip',
  ClientDataDisabledViewTooltip = 'client-data-disabled-view-tooltip',
  ClientDataRevertBranchDialogText = 'client-data-revert-branch-dialog-text',
  ClientDataRevertBranchDialogTextAuthors = 'client-data-revert-branch-dialog-text-authors',
  ClientDataRevertBranchDialogTextAuthorsMultiple = 'client-data-revert-branch-dialog-text-authors-multiple',
  ClientDataRevertBranchDialogTextChangedTables = 'client-data-revert-branch-dialog-text-changed-tables',
  ClientDataRevertBranchDialogTextChangedTablesOne = 'client-data-revert-branch-dialog-text-changed-tables_one',
  ClientDataRevertBranchDialogTextChangedTablesOther = 'client-data-revert-branch-dialog-text-changed-tables_other',
  ClientDataRevertBranchDialogTextChangedTablesMultiple = 'client-data-revert-branch-dialog-text-changed-tables-multiple',
  ClientDataRevertBranchDialogTextMetadataChanges = 'client-data-revert-branch-dialog-text-metadata-changes',
  ClientDataRevertBranchDialogRevertButton = 'client-data-revert-branch-dialog-revert-button',
  ClientDataRevertBranchDialogChangesButton = 'client-data-revert-branch-dialog-changes-button',
  ClientDataEditNoteDialogTitle = 'client-data-edit-note-dialog-title',
  ClientDataSearchButtonDisabledTooltip = 'client-data-search-button-disabled-tooltip',
  ClientDataSearchPlaceholder = 'client-data-search-placeholder',
  ClientDataSearchFormulas = 'client-data-search-formulas',
  ClientDataMatchCase = 'client-data-match-case',
  ClientDataSearchReplaceColumnHeaderWarning = 'client-data-search-replace-column-header-warning',
  ClientDataSearchReplacePlaceholder = 'client-data-search-replace-placeholder',
  ClientDataSearchReplaceSuccess = 'client-data-search-replace-success',
  ClientDataNoteField = 'client-data-note-field',
  ClientDataHotfixDialogTitle = 'client-data-hotfix-dialog-title',
  ClientDataHotfixDescriptionField = 'client-data-hotfix-description-field',
  ClientDataBranchClientUpdate = 'client-data-branch-client-update',
  ClientDataBranchPublished = 'client-data-branch-published',
  ClientDataBranchUnpublished = 'client-data-branch-unpublished',
  ClientDataBranchHotfix = 'client-data-branch-hotfix',
  ClientDataBranchPricing = 'client-data-branch-pricing',
  ClientDataBranchPricingSurcharge = 'client-data-branch-pricing-surcharge',
  ClientDataBranchSiteDetail = 'client-data-branch-site-detail',
  ClientDataBranchSizeBased = 'client-data-branch-size-based',
  ClientDataBranchLoadingLabel = 'client-data-branch-loading-label',
  ClientDataBranchMenuPublished = 'client-data-branch-menu-published',
  ClientDataBranchMenuUnpublished = 'client-data-branch-menu-unpublished',
  ClientDataBranchMenuHotfix = 'client-data-branch-menu-hotfix',
  ClientDataBranchMenuPricing = 'client-data-branch-menu-pricing',
  ClientDataBranchMenuSiteDetail = 'client-data-branch-menu-site-detail',
  ClientDataBranchMenuCreateBranch = 'client-data-branch-menu-create-branch',
  ClientDataBranchMenuChangesNoChanges = 'client-data-branch-menu-changes-no-changes',
  ClientDataBranchMenuChangesJustYou = 'client-data-branch-menu-changes-just-you',
  ClientDataBranchMenuChangesAnotherPerson = 'client-data-branch-menu-changes-another-person',
  ClientDataBranchMenuChangesAnotherPersonOne = 'client-data-branch-menu-changes-another-person_one',
  ClientDataBranchMenuChangesAnotherPersonOther = 'client-data-branch-menu-changes-another-person_other',
  ClientDataBranchMenuChangesYouAndAnother = 'client-data-branch-menu-changes-you-and-another',
  ClientDataBranchMenuChangesYouAndAnotherOne = 'client-data-branch-menu-changes-you-and-another_one',
  ClientDataBranchMenuChangesYouAndAnotherOther = 'client-data-branch-menu-changes-you-and-another_other',
  ClientDataBranchToolTipSummary = 'client-data-branch-tooltip-summary',
  ClientDataBranchToolTipSummaryNoChanges = 'client-data-branch-tooltip-summary-no-changes',
  ClientDataBranchToolTipSummaryYouAuthor = 'client-data-branch-tooltip-summary-you-author',
  ClientDataBranchToolTipSummaryAuthors = 'client-data-branch-tooltip-summary-authors',
  ClientDataBranchToolTipSummaryAuthorsMultiple = 'client-data-branch-tooltip-summary-authors-multiple',
  ClientDataBranchToolTipSummaryChangedTables = 'client-data-branch-tooltip-summary-changed-tables',
  ClientDataBranchToolTipSummaryChangedTablesOne = 'client-data-branch-tooltip-summary-changed-tables_one',
  ClientDataBranchToolTipSummaryChangedTablesOther = 'client-data-branch-tooltip-summary-changed-tables_other',
  ClientDataBranchToolTipSummaryChangedTablesMultiple = 'client-data-branch-tooltip-summary-changed-tables-multiple',
  ClientDataBranchToolTipSummaryMetadataChanges = 'client-data-branch-tooltip-summary-metadata-changes',
  ClientDataBranchToolTipSummarySeeChangeSummary = 'client-data-branch-tooltip-summary-see-change-summary',
  ClientDataChangesSummaryTitle = 'client-data-changes-summary-title',
  ClientDataChangesSummaryChange = 'client-data-changes-summary-change',
  ClientDataChangesSummaryPublished = 'client-data-changes-summary-published',
  ClientDataChangesSummaryChangedBy = 'client-data-changes-summary-changed-by',
  ClientDataChangesSummaryLastChange = 'client-data-changes-summary-last-change',
  ClientDataUpdateButton = 'client-data-update-button',
  ClientDataRollbackOpenButton = 'client-data-rollback-open-button',
  ClientDataRollbackDialogOpenBranchText = 'client-data-rollback-dialog-open-branch-text',
  ClientDataRollbackDialogTitle = 'client-data-rollback-dialog-title',
  ClientDataRollbackDialogText = 'client-data-rollback-dialog-text',
  ClientDataRollbackDialogViewData = 'client-data-rollback-dialog-view-data',
  ClientDataPublishUpdatesDialogText = 'client-data-publish-updates-dialog-text',
  ClientDataPublishUpdatesSupplierDialogText = 'client-data-publish-updates-supplier-dialog-text',
  ClientDataPublishUpdatesReferenceVersionBump = 'client-data-publish-updates-reference-version-bump',
  ClientDataPublishUpdatesSupplierVersionBump = 'client-data-publish-updates-supplier-version-bump',
  ClientDataPublishUpdatesSupplierNotPublishedVersionBump = 'client-data-publish-updates-supplier-not-published-version-bump',
  ClientDataPublishUpdatesSupplierVendorVersionBump = 'client-data-publish-updates-supplier-vendor-version-bump',
  ClientDataPublishUpdatesSupplierVendorNotPublishedVersionBump = 'client-data-publish-updates-supplier-vendor-not-published-version-bump',
  ClientDataPublishButton = 'client-data-publish-button',
  ClientDataPublishCustomerIsAware = 'client-data-publish-customer-is-aware',
  ClientDataPublishDescriptionField = 'client-data-publish-description-field',
  ClientDataPublishDialogText = 'client-data-publish-dialog-text',
  ClientDataPublishDialogChecklistText = 'client-data-publish-dialog-checklist-text',
  ClientDataPublishDialogNotProductionText = 'client-data-publish-dialog-not-production-text',
  ClientDataPublishDialogTitle = 'client-data-publish-dialog-title',
  ClientDataPublishDialogTitleDevelopment = 'client-data-publish-dialog-title_development',
  ClientDataPublishDialogTitleLocalhost = 'client-data-publish-dialog-title_localhost',
  ClientDataPublishDialogTitleProduction = 'client-data-publish-dialog-title_production',
  ClientDataPublishDialogTitleStaging = 'client-data-publish-dialog-title_staging',
  ClientDataPublishError = 'client-data-publish-error',
  ClientDataPublishErrorDevelopment = 'client-data-publish-error_development',
  ClientDataPublishErrorLocalhost = 'client-data-publish-error_localhost',
  ClientDataPublishErrorProduction = 'client-data-publish-error_production',
  ClientDataPublishErrorStaging = 'client-data-publish-error_staging',
  ClientDataPublishErrorSome = 'client-data-publish-error-some',
  ClientDataPublishErrorSomeDevelopment = 'client-data-publish-error-some_development',
  ClientDataPublishErrorSomeLocalhost = 'client-data-publish-error-some_localhost',
  ClientDataPublishErrorSomeProduction = 'client-data-publish-error-some_production',
  ClientDataPublishErrorSomeStaging = 'client-data-publish-error-some_staging',
  ClientDataPublishErrorOpenUnpublishedBranch = 'client-data-publish-error-open-unpublished-branch',
  ClientDataPublishPreviewedChanges = 'client-data-publish-previewed-changes',
  ClientDataPublishResolveNowButton = 'client-data-publish-resolve-now-button',
  ClientDataPublishSuccess = 'client-data-publish-success',
  ClientDataPublishSuccessDevelopment = 'client-data-publish-success_development',
  ClientDataPublishSuccessLocalhost = 'client-data-publish-success_localhost',
  ClientDataPublishSuccessProduction = 'client-data-publish-success_production',
  ClientDataPublishSuccessStaging = 'client-data-publish-success_staging',
  ClientDataPublishSuccessConflicts = 'client-data-publish-success-conflicts',
  ClientDataPublishSuccessNoConflicts = 'client-data-publish-success-no-conflicts',
  ClientDataPublishPublishingVendors = 'client-data-publish-publishing-vendors',
  ClientDataPublishPublishingVendorsDevelopment = 'client-data-publish-publishing-vendors_development',
  ClientDataPublishPublishingVendorsLocalhost = 'client-data-publish-publishing-vendors_localhost',
  ClientDataPublishPublishingVendorsProduction = 'client-data-publish-publishing-vendors_production',
  ClientDataPublishPublishingVendorsStaging = 'client-data-publish-publishing-vendors_staging',
  ClientDataPublishPricingConfirmButton = 'client-data-publish-pricing-confirm-button',
  ClientDataPublishPricingConfirmMessage = 'client-data-publish-pricing-confirm-message',
  ClientDataNewSupplierUpdatesDialogText = 'client-data-new-supplier-updates-dialog-text',
  ClientDataPublishUnresolvedMergeConflicts = 'client-data-publish-unresolved-merge-conflicts',
  ClientDataPublishVendorsList = 'client-data-publish-vendors-list',
  ClientDataPublishVerifiedQuotes = 'client-data-publish-verified-quotes',
  ClientDataPublishedBranchReadOnly = 'client-data-published-branch-read-only',
  ClientDataGenerateIconDialogGenerating = 'client-data-generate-icon-dialog-generating',
  ClientDataGenerateIconDialogSuccess = 'client-data-generate-icon-dialog-success',
  ClientDataGenerateIconDialogError = 'client-data-generate-icon-dialog-error',
  ClientDataGenerateOptionIconDialogPreparing = 'client-data-generate-option-icon-dialog-preparing',
  ClientDataGenerateOptionIconDialogGenerating = 'client-data-generate-option-icon-dialog-generating',
  ClientDataGenerateOptionIconDialogSuccess = 'client-data-generate-option-icon-dialog-success',
  ClientDataGenerateOptionIconDialogSuccessOne = 'client-data-generate-option-icon-dialog-success_one',
  ClientDataGenerateOptionIconDialogSuccessOther = 'client-data-generate-option-icon-dialog-success_other',
  ClientDataGenerateOptionIconDialogUploading = 'client-data-generate-option-icon-dialog-uploading',
  ClientDataGenerateOptionIconDialogUploadingOne = 'client-data-generate-option-icon-dialog-uploading_one',
  ClientDataGenerateOptionIconDialogUploadingOther = 'client-data-generate-option-icon-dialog-uploading_other',
  ClientDataGenerateOptionIconDialogError = 'client-data-generate-option-icon-dialog-error',
  ClientDataTableMenuFilterAllSheets = 'client-data-table-menu-filter-all-sheets',
  ClientDataTableMenuFilterSheetsWithChanges = 'client-data-table-menu-filter-sheets-with-changes',
  ClientDataTableMenuChangeSummary = 'client-data-table-menu-change-summary',
  ClientDataUploadImageTooLarge = 'client-data-upload-image-too-large',
  ClientDataUploadImageInvalidType = 'client-data-upload-image-invalid-type',
  ClientDataUploadImageUnknownError = 'client-data-upload-image-unknown-error',
  ClientDataUploadFileTooLarge = 'client-data-upload-file-too-large',
  ClientDataUploadFileInvalidType = 'client-data-upload-file-invalid-type',
  ClientDataUploadFileUnknownError = 'client-data-upload-file-unknown-error',
  ClientDataUploadFileDropzone = 'client-data-upload-file-dropzone',
  ClientDataHeaderMenuGoToSource = 'client-data-header-menu-go-to-source',
  ClientDataHeaderTooltipSource = 'client-data-header-tooltip-source',
  ClientDataHeaderTooltipSourceBuiltIn = 'client-data-header-tooltip-source-built-in',
  ClientDataHeaderTooltipSourceAndOtherTables = 'client-data-header-tooltip-source-and-other-tables',
  ClientSearchResultsThisVendor = 'client-search-results-this-vendor',
  ColumnFilterMenuColumnsLabel = 'column-filter-menu-columns-label',
  ComponentTab = 'component-tab',
  ConfiguratorDialogSubdomainDefault = 'configurator-dialog-subdomain-default',
  ConfiguratorDialogFabCreateGroup = 'configurator-dialog-fab-create-group',
  ConfiguratorDialogFabCreateGroupWithDefault = 'configurator-dialog-fab-create-group-with-default',
  ConfiguratorDialogFabAddNewConfigurator = 'configurator-dialog-fab-add-new-configurator',
  ConfiguratorDialogFabAddExistingConfigurator = 'configurator-dialog-fab-add-existing-configurator',
  ConfiguratorDialogAddConfiguratorTitle = 'configurator-dialog-add-configurator-title',
  ConfiguratorDialogUpdateConfiguratorTitle = 'configurator-dialog-update-configurator-title',
  ConfiguratorDialogCreatingDataset = 'configurator-dialog-creating-dataset',
  ConfiguratorDialogCreatingBucket = 'configurator-dialog-creating-bucket',
  ConfiguratorDialogCreatingDistribution = 'configurator-dialog-creating-distribution',
  ConfiguratorDialogCreatingStagingSite = 'configurator-dialog-creating-staging-site',
  ConfiguratorDialogRequestingCertificate = 'configurator-dialog-requesting-certificate',
  ConfiguratorDialogFetchingVendorData = 'configurator-dialog-fetching-vendor-data',
  ConfiguratorDialogUpdatingVendorData = 'configurator-dialog-updating-vendor-data',
  ConfiguratorFormConfiguratorHelp = 'configurator-form-configurator-help',
  ConfiguratorFormSupplierDataHelp = 'configurator-form-supplier-data-help',
  ConfiguratorFormVendorDataHelp = 'configurator-form-vendor-data-help',
  ConfiguratorFormFieldName = 'configurator-form-field-name',
  ConfiguratorFormFieldKey = 'configurator-form-field-key',
  ConfiguratorFormFieldSubdomain = 'configurator-form-field-subdomain',
  ConfiguratorFormFieldSupplier = 'configurator-form-field-supplier',
  ConfiguratorFormFieldDealer = 'configurator-form-field-dealer',
  ConfiguratorFormFieldDomain = 'configurator-form-field-domain',
  ConfiguratorFormFieldConfigurator = 'configurator-form-field-configurator',
  ConfiguratorFormFieldCopyFromDataset = 'configurator-form-field-copy-from-dataset',
  ConfiguratorFormFieldGroupName = 'configurator-form-field-group-name',
  ConfiguratorFormFieldDefaultSupplier = 'configurator-form-field-default-supplier',
  ConfiguratorFormFieldConfiguratorSheds = 'configurator-form-field-configurator-sheds',
  ConfiguratorFormFieldConfiguratorCarports = 'configurator-form-field-configurator-carports',
  ConfiguratorFormFieldSupplierDataTemplateNone = 'configurator-form-field-supplier-data-template-none',
  ConfiguratorMenuConfirmRemoveMessage = 'configurator-menu-confirm-remove-message',
  ConfiguratorMenuConfirmRemoveTitle = 'configurator-menu-confirm-remove-title',
  ConfiguratorMenuEditOption = 'configurator-menu-edit-option',
  ConfiguratorMenuRemoveOption = 'configurator-menu-remove-option',
  ConfirmationDialogConfirmButton = 'confirmation-dialog-confirm-button',
  ConfirmationDialogDefaultDescription = 'confirmation-dialog-default-description',
  ContextMenuRowDisabledTooltip = 'context-menu-row-disabled-tooltip',
  DateRangeDialogDivider = 'date-range-dialog-divider',
  DateRangeDialogEndDateLabel = 'date-range-dialog-end-date-label',
  DateRangeDialogStartDateLabel = 'date-range-dialog-start-date-label',
  DateRangeDialogTitle = 'date-range-dialog-title',
  DealerDialogAdvancedSection = 'dealer-dialog-advanced-section',
  DealerDialogContactBarHTMLField = 'dealer-dialog-contact-bar-html-field',
  DealerDialogCustomDealerIdCheckbox = 'dealer-dialog-custom-dealer-id-checkbox',
  DealerDialogCustomDealerIdField = 'dealer-dialog-custom-dealer-id-field',
  DealerDialogCustomLogoError = 'dealer-dialog-custom-logo-error',
  DealerDialogCustomLogoUrlField = 'dealer-dialog-custom-logo-url-field',
  DealerDialogDealerCityField = 'dealer-dialog-dealer-city-field',
  DealerDialogDealerEmailField = 'dealer-dialog-dealer-email-field',
  DealerDialogDealerIdField = 'dealer-dialog-dealer-id-field',
  DealerDialogDealerIdFieldHelpText = 'dealer-dialog-dealer-id-field-help-text',
  DealerDialogDealerNameField = 'dealer-dialog-dealer-name-field',
  DealerDialogDealerSiteLinkField = 'dealer-dialog-dealer-site-link-field',
  DealerDialogDealerStateField = 'dealer-dialog-dealer-state-field',
  DealerDialogDealerZipField = 'dealer-dialog-dealer-zip-field',
  DealerDialogEmailAddressForRepliesField = 'dealer-dialog-email-address-for-replies-field',
  DealerDialogEmailCopySameCheckbox = 'dealer-dialog-email-copy-same-checkbox',
  DealerDialogEmailCopySameField = 'dealer-dialog-email-copy-same-field',
  DealerDialogEmailLogoError = 'dealer-dialog-email-logo-error',
  DealerDialogEmailLogoUrlField = 'dealer-dialog-email-logo-url-field',
  DealerDialogEmailReplyToSameCheckbox = 'dealer-dialog-email-reply-to-same-checkbox',
  DealerDialogEmailReplyToSameField = 'dealer-dialog-email-reply-to-same-field',
  DealerDialogHomeLinkField = 'dealer-dialog-home-link-field',
  DealerDialogIntegrationField = 'dealer-dialog-integration-field',
  DealerDialogTitle = 'dealer-dialog-title',
  DealerDialogUseDealerEmailCheckbox = 'dealer-dialog-use-dealer-email-checkbox',
  DealerMenuConfirmRemoveMessage = 'dealer-menu-confirm-remove-message',
  DealerMenuConfirmRemoveTitle = 'dealer-menu-confirm-remove-title',
  DealerMenuRemoveOption = 'dealer-menu-remove-option',
  DefaultDealerName = 'default-dealer-name',
  DefaultOwnerName = 'default-owner-name',
  DefaultStatusName = 'default-status-name',
  DepositAmountsAddAmountsButton = 'deposit-amounts-add-amounts-button',
  DepositAmountsHigherText = 'deposit-amounts-higher-text',
  DepositAmountsOutOfOrder = 'deposit-amounts-out-of-order',
  DepositAmountsPlaceholderAmount = 'deposit-amounts-placeholder-amount',
  DepositAmountsPlaceholderPercent = 'deposit-amounts-placeholder-percent',
  DepositAmountsRangeDivider = 'deposit-amounts-range-divider',
  DepositAmountsRangeEnd = 'deposit-amounts-range-end',
  DepositAmountsRangeStart = 'deposit-amounts-range-start',
  DevelopmentEnvironment = 'development-environment',
  DialogApplyButton = 'dialog-apply-button',
  DialogCancelButton = 'dialog-cancel-button',
  DialogChangeButton = 'dialog-change-button',
  DialogCloseButton = 'dialog-close-button',
  DialogContactSupportButton = 'dialog-contact-support-button',
  DialogCreateButton = 'dialog-create-button',
  DialogDeleteButton = 'dialog-delete-button',
  DialogDoneButton = 'dialog-done-button',
  DialogDownloadButton = 'dialog-download-button',
  DialogFilterButton = 'dialog-filter-button',
  DialogGotItButton = 'dialog-got-it-button',
  DialogImpersonateButton = 'dialog-impersonate-button',
  DialogSaveButton = 'dialog-save-button',
  DialogUpdateButton = 'dialog-update-button',
  DialogTypeAdd = 'dialog-type-add',
  DialogTypeEdit = 'dialog-type-edit',
  EnableReplaceButton = 'enable-replace-button',
  ExportButton = 'export-button',
  FieldEmail = 'field-email',
  FieldFirstName = 'field-first-name',
  FieldLastName = 'field-last-name',
  FieldPassword = 'field-password',
  FieldPermissions = 'field-permissions',
  FieldPhone = 'field-phone',
  FieldConfirmNewPassword = 'field-confirm-new-password',
  FilterBy45Days = 'filter-by-45-days',
  FilterBy90Days = 'filter-by-90-days',
  FilterByAllTime = 'filter-by-all-time',
  FilterByCustomDates = 'filter-by-custom-dates',
  FilterByDealer = 'filter-by-dealer',
  FilterByLastMonth = 'filter-by-last-month',
  FilterMessage = 'filter-message',
  FilterByOwner = 'filter-by-owner',
  FilterBySite = 'filter-by-site',
  FilterByStatus = 'filter-by-status',
  FilterBySubmitStatus = 'filter-by-submit-status',
  FilterByThisMonth = 'filter-by-this-month',
  FilterSelectAll = 'filter-select-all',
  FilterTitlePrefix = 'filter-title-prefix',
  FindAllButton = 'find-all-button',
  FindOneButton = 'find-one-button',
  ForgotPasswordConfirmNewPasswordErrorMessage = 'forgot-password-confirm-new-password-error-message',
  ForgotPasswordConfirmNewPasswordInput = 'forgot-password-confirm-new-password-input',
  ForgotPasswordLink = 'forgot-password-link',
  ForgotPasswordMessage = 'forgot-password-message',
  ForgotPasswordNewPasswordInput = 'forgot-password-new-password-input',
  ForgotPasswordResendVerificationLink = 'forgot-password-resend-verification-link',
  ForgotPasswordResetMessage = 'forgot-password-reset-message',
  ForgotPasswordResetPasswordButton = 'forgot-password-reset-password-button',
  ForgotPasswordResetSuccessMessage = 'forgot-password-reset-success-message',
  ForgotPasswordReturnToSignInButton = 'forgot-password-return-to-sign-in-button',
  ForgotPasswordSendVerificationCodeButton = 'forgot-password-send-verification-code-button',
  ForgotPasswordVerificationCodeInput = 'forgot-password-verification-code-input',
  GenerateStyleIcons = 'generate-style-icons',
  GoToCellHiddenConfirmButton = 'go-to-cell-hidden-confirm-button',
  GoToCellHiddenConfirmTitle = 'go-to-cell-hidden-confirm-title',
  GoToCellHiddenConfirmMessage = 'go-to-cell-hidden-confirm-message',
  GoToCellHiddenColumnConfirmButton = 'go-to-cell-hidden-column-confirm-button',
  GoToCellHiddenColumnConfirmTitle = 'go-to-cell-hidden-column-confirm-title',
  GoToCellHiddenColumnConfirmMessage = 'go-to-cell-hidden-column-confirm-message',
  GoToCellNotFound = 'go-to-cell-not-found',
  GroupConfiguratorsTab = 'group-configurators-tab',
  GroupDialogAddGroupTitle = 'group-dialog-add-group-title',
  GroupDialogUpdateGroupTitle = 'group-dialog-update-group-title',
  GroupMemberFilterMenuClearButton = 'group-member-filter-menu-clear-button',
  GroupMemberFilterMenuDealerOption = 'group-member-filter-menu-dealer-option',
  GroupMemberFilterMenuPermissionOption = 'group-member-filter-menu-permission-option',
  GroupMemberFilterMessage = 'group-member-filter-message',
  GroupMemberMenuConfirmDeleteMessage = 'group-member-menu-confirm-delete-message',
  GroupMemberMenuConfirmDeleteUserMessage = 'group-member-menu-confirm-delete-user-message',
  GroupMemberMenuConfirmDeleteTitle = 'group-member-menu-confirm-delete-title',
  GroupMemberMenuDelete = 'group-member-menu-delete',
  GroupMemberMenuImpersonate = 'group-member-menu-impersonate',
  GroupMemberMenuResendInvitation = 'group-member-menu-resend-invitation',
  GroupMemberMenuResetPassword = 'group-member-menu-reset-password',
  GroupMemberMenuSendInvitation = 'group-member-menu-send-invitation',
  GroupMembersTab = 'group-members-tab',
  GroupMenuConfirmDeleteMessage = 'group-menu-confirm-delete-message',
  GroupMenuConfirmDeleteTitle = 'group-menu-confirm-delete-title',
  GroupMenuEditGroupOption = 'group-menu-edit-group-option',
  GroupMenuGoToGroupOption = 'group-menu-go-to-group-option',
  GroupMenuRemoveGroupOption = 'group-menu-remove-group-option',
  ImpersonationDialogMessage = 'impersonation-dialog-message',
  ImpersonationDialogTitle = 'impersonation-dialog-title',
  ImpersonationFieldValidationCode = 'impersonation-field-validation-code',
  I18nDialogRemoveOverride = 'i18n-dialog-remove-override',
  I18nDialogRemoveKey = 'i18n-dialog-remove-key',
  I18nFormOriginalTextField = 'i18n-form-original-text-field',
  I18nFormOverrideTextField = 'i18n-form-override-text-field',
  I18nLanguage = 'i18n-language',
  KeyInUse = 'key-in-use',
  MenuClientDataOption = 'menu-client-data-option',
  MenuDealersOption = 'menu-dealers-option',
  MenuGroupsOption = 'menu-groups-option',
  MenuLeadsOption = 'menu-leads-option',
  MenuOrdersOption = 'menu-orders-option',
  MenuPaymentsOption = 'menu-payments-option',
  MenuPaymentsOptionConnected = 'menu-payments-option-connected',
  MenuPricingOption = 'menu-pricing-option',
  MenuReportsOption = 'menu-reports-option',
  MenuSettingsOption = 'menu-settings-option',
  MenuSitesOption = 'menu-sites-option',
  MenuUsageOption = 'menu-usage-option',
  MenuUsersOption = 'menu-users-option',
  MUIDataGridColumn = 'mui-data-grid-column',
  MUIDataGridFilter = 'mui-data-grid-filter',
  MUIDataGridFilterCountFiltered = 'mui-data-grid-filter-count-filtered',
  MUIDataGridFilterCountNoFilter = 'mui-data-grid-filter-count-no-filter',
  MUIDataGridFilterMessage = 'mui-data-grid-filter-message',
  MUIDataGridFilterMessageCustom = 'mui-data-grid-filter-message_custom',
  MUIDataGridManageColumns = 'mui-data-grid-manage-columns',
  MUIDataGridShowColumns = 'mui-data-grid-show-columns',
  MUIDataGridSortAsc = 'mui-data-grid-sort-asc',
  MUIDataGridSortDateAsc = 'mui-data-grid-sort-date-asc',
  MUIDataGridSortDateDesc = 'mui-data-grid-sort-date-desc',
  MUIDataGridSortDesc = 'mui-data-grid-sort-desc',
  MUIDataGridSortNumberAsc = 'mui-data-grid-sort-number-asc',
  MUIDataGridSortNumberDesc = 'mui-data-grid-sort-number-desc',
  MUIDataGridPinToLeft = 'mui-data-grid-pin-to-left',
  MUIDataGridPinToRight = 'mui-data-grid-pin-to-right',
  MUIDataGridUnsort = 'mui-data-grid-unsort',
  NoLogsAvailable = 'no-logs-available',
  NotificationSettingsLeadsEmailDealer = 'notification-settings-leads-email-dealer',
  NotificationSettingsLeadsEmailOwner = 'notification-settings-leads-email-owner',
  NotificationSettingsLeadsTitle = 'notification-settings-leads-title',
  OrderDealerSelectorNotAssigned = 'order-dealer-selector-not-assigned',
  OrderDetailsDialogAmount = 'order-details-dialog-amount',
  OrderDetailsDialogDate = 'order-details-dialog-date',
  OrderDetailsDialogDealer = 'order-details-dialog-dealer',
  OrderDetailsDialogName = 'order-details-dialog-name',
  OrderDetailsDialogOwner = 'order-details-dialog-owner',
  OrderDetailsDialogHash = 'order-details-dialog-hash',
  OrderDetailsDialogReference = 'order-details-dialog-reference',
  OrderDetailsDialogSmartBuild = 'order-details-dialog-smartbuild',
  OrderDetailsDialogSite = 'order-details-dialog-site',
  OrderDetailsDialogStatus = 'order-details-dialog-status',
  OrderDetailsDialogStyle = 'order-details-dialog-style',
  OrderFilterCustom = 'order-filter-custom',
  OrderFilterLast45Days = 'order-filter-last-45-days',
  OrderFilterLast90Days = 'order-filter-last-90-days',
  OrderFilterThisMonth = 'order-filter-this-month',
  OrderFilterLastMonth = 'order-filter-last-month',
  OrderFilterAllTime = 'order-filter-all-time',
  OrderStatusAddCustomStatus = 'order-status-add-custom-status',
  OrderStatusDialogTitle = 'order-status-dialog-title',
  OrderStatusDialogMessage = 'order-status-dialog-message',
  OrderStatusField = 'order-status-field',
  OrderStatusNewLead = 'order-status-new-lead',
  OrderStatusQualified = 'order-status-qualified',
  OrderStatusClosedWon = 'order-status-closed-won',
  OrderStatusClosedLost = 'order-status-closed-lost',
  OrderStatusNotALead = 'order-status-not-a-lead',
  LanguageDialogCimode = 'language-dialog-cimode',
  LanguageDialogMessage = 'language-dialog-message',
  LanguageDialogState = 'language-dialog-state',
  LanguageDialogTitle = 'language-dialog-title',
  LatestLogTitle = 'latest-log-title',
  LeadConfirmDeleteTitle = 'lead-confirm-delete-title',
  LeadConfirmDeleteMessage = 'lead-confirm-delete-message',
  LeadsPopupDelayNoTimeDelay = 'leads-popup-delay-no-time-delay',
  LeadsPopupDelayAfterThirtySeconds = 'leads-popup-delay-after-thirty-seconds',
  LeadsPopupDelayAfterOneMinute = 'leads-popup-delay-after-one-minute',
  LeadsPopupDefaultTitle = 'leads-popup-default-title',
  LeadsPopupDefaultMessage = 'leads-popup-default-message',
  PasswordChangePassword = 'password-change-password',
  PasswordSubtitle = 'password-subtitle',
  PasswordCurrentPassword = 'password-current-password',
  PasswordNewPassword = 'password-new-password',
  PasswordUpdatePasswordButton = 'password-update-password-button',
  PasswordPasswordsDontMatchError = 'password-passwords-dont-match-error',
  PasteAnywayButton = 'paste-anyway-button',
  PaymentGridHeaderBillingAddress = 'payment-grid-header-billing-address',
  PaymentGridHeaderBillingCity = 'payment-grid-header-billing-city',
  PaymentGridHeaderBillingPostalCode = 'payment-grid-header-billing-postal-code',
  PaymentGridHeaderBillingState = 'payment-grid-header-billing-state',
  PaymentGridHeaderDate = 'payment-grid-header-date',
  PaymentGridHeaderEmail = 'payment-grid-header-email',
  PaymentGridHeaderName = 'payment-grid-header-name',
  PaymentGridHeaderOrderNumber = 'payment-grid-header-order-number',
  PaymentGridHeaderPaymentAmount = 'payment-grid-header-payment-amount',
  PaymentGridHeaderPaymentMethod = 'payment-grid-header-payment-method',
  PaymentGridHeaderPaymentProvider = 'payment-grid-header-payment-provider',
  PaymentGridHeaderPaymentStatus = 'payment-grid-header-payment-status',
  PaymentGridHeaderPhone = 'payment-grid-header-phone',
  PaymentGridHeaderProcessingFee = 'payment-grid-header-processing-fee',
  PaymentGridHeaderTotalWithFee = 'payment-grid-header-total-with-fee',
  PaymentGridHeaderTransactionId = 'payment-grid-header-transaction-id',
  PaymentGridRowPaymentStatus = 'payment-grid-row-payment-status',
  PaymentGridRowPaymentStatusPending = 'payment-grid-row-payment-status_pending',
  PaymentGridRowPaymentStatusSucceeded = 'payment-grid-row-payment-status_succeeded',
  PaymentGridRowPaymentStatusAuthorized = 'payment-grid-row-payment-status_authorized',
  PaymentGridRowPaymentStatusCancelled = 'payment-grid-row-payment-status_cancelled',
  PaymentGridRowPaymentStatusRefused = 'payment-grid-row-payment-status_refused',
  PaymentGridRowPaymentStatusRefunded = 'payment-grid-row-payment-status_refunded',
  PermissionAdmin = 'permission-admin',
  PermissionManager = 'permission-manager',
  PermissionSales = 'permission-sales',
  PreviewButton = 'preview-button',
  PublishButton = 'publish-button',
  PricingAmountAddRule = 'pricing-amount-add-rule',
  PricingAmountChange = 'pricing-amount-change',
  PricingAmountDefault = 'pricing-amount-default',
  PricingAmountSurchargeType = 'pricing-amount-surcharge-type',
  PricingAmountChangeType = 'pricing-amount-change-type',
  PricingAmountVaryBy = 'pricing-amount-vary-by',
  PricingBaseContactSupportMessage = 'pricing-base-contact-support-message',
  PricingBaseContactSupportDialogTitle = 'pricing-base-contact-support-dialog-title',
  PricingBaseAccordionHiddenRegion = 'pricing-base-accordion-hidden-region',
  PricingBaseAccordionNonePriceChanged = 'pricing-base-accordion-none-price-changed',
  PricingBaseAccordionCountPricesChanged = 'pricing-base-accordion-count-prices-changed',
  PricingBaseAccordionCountPricesChangedMissing = 'pricing-base-accordion-count-prices-changed-missing',
  PricingBaseAccordionAllPricesChanged = 'pricing-base-accordion-all-prices-changed',
  PricingBaseAccordionAllPricesChangedMissing = 'pricing-base-accordion-all-prices-changed-missing',
  PricingBaseAccordionDefaultLabel = 'pricing-base-accordion-default-label',
  PricingBaseAccordionEditSheetTitleDialogTitle = 'pricing-base-accordion-edit-sheet-title-dialog-title',
  PricingBaseAccordionMissingPrice = 'pricing-base-accordion-missing-price',
  PricingBaseAccordionMissingPriceOne = 'pricing-base-accordion-missing-price_one',
  PricingBaseAccordionMissingPriceOther = 'pricing-base-accordion-missing-price_other',
  PricingBaseAccordionPricingSheetTitle = 'pricing-base-accordion-pricing-sheet-title',
  PricingBaseAddSizeMissingRoofPitchTitle = 'pricing-base-add-size-missing-roof-pitch-title',
  PricingBaseAddSizeMissingRoofPitchMessage = 'pricing-base-add-size-missing-roof-pitch-message',
  PricingBaseAddSizeButtonTitle = 'pricing-base-add-size-button-title',
  PricingBaseAddSizeDialogLength = 'pricing-base-add-size-dialog-length',
  PricingBaseAddSizeDialogTitle = 'pricing-base-add-size-dialog-title',
  PricingBaseAddSizeDialogWidth = 'pricing-base-add-size-dialog-width',
  PricingbaseaddSizeFormErrorSizeExists = 'pricing-base-add-size-form-error-size-exists',
  PricingbaseaddSizeFormErrorZero = 'pricing-base-add-size-form-error-zero',
  PricingbaseaddSizeFormImperialUnit = 'pricing-base-add-size-form-imperial-unit',
  PricingbaseaddSizeFormMetricUnit = 'pricing-base-add-size-form-metric-unit',
  PricingBaseAttributeNoneFound = 'pricing-base-attribute-none-found',
  PricingBaseBranchNotAvailableTitle = 'pricing-base-branch-not-available-title',
  PricingBaseBranchNotAvailableMessage = 'pricing-base-branch-not-available-message',
  PricingBaseClipboardExceedsSpaceWarning = 'pricing-base-clipboard-exceeds-space-warning',
  PricingBaseDataChangeDialogMessage = 'pricing-base-data-change-dialog-message',
  PricingBaseDefaultRegionHeaderListViewLabel = 'pricing-base-default-region-header-list-view-label',
  PricingBaseDeleteSize = 'pricing-base-delete-size',
  PricingBaseDeleteSizeOne = 'pricing-base-delete-size_one',
  PricingBaseDeleteSizeOther = 'pricing-base-delete-size_other',
  PricingBaseDeletingAllPricesDialogTitle = 'pricing-base-deleting-all-prices-dialog-title',
  PricingBaseDeletingAllPricesDialogMessage = 'pricing-base-deleting-all-prices-dialog-message',
  PricingBaseDeletingPriceDialog = 'pricing-base-deleting-price-dialog',
  PricingBaseDeletePriceConfirmationDialogMessage = 'pricing-base-delete-price-confirmation-dialog-message',
  PricingBaseDeletePriceConfirmationDialogMessageSizes = 'pricing-base-delete-price-confirmation-dialog-message-sizes',
  PricingBaseDeletePriceConfirmationDialogMessageSizes_one = 'pricing-base-delete-price-confirmation-dialog-message-sizes_one',
  PricingBaseDeletePriceConfirmationDialogMessageSizes_other = 'pricing-base-delete-price-confirmation-dialog-message-sizes_other',
  PricingBaseDeletePriceConfirmationDialogSize = 'pricing-base-delete-price-confirmation-dialog-size',
  PricingBaseHiddenPrice = 'pricing-base-hidden-price',
  PricingBaseHiddenRegionPriceHeader = 'pricing-base-hidden-region-price-header',
  PricingBaseHiddenStylePriceHeader = 'pricing-base-hidden-style-price-header',
  PricingBasePreviewConfirmationTitle = 'pricing-base-preview-confirmation-title',
  PricingBasePreviewConfirmationMessage = 'pricing-base-preview-confirmation-message',
  PricingBasePreviewConfirmationButton = 'pricing-base-preview-confirmation-button',
  PricingBasePublishDialogText = 'pricing-base-publish-dialog-text',
  PricingBasePublishDialogTextAuthors = 'pricing-base-publish-dialog-text-authors',
  PricingBasePublishDialogTextAuthorsMultiple = 'pricing-base-publish-dialog-text-authors-multiple',
  PricingBasePublishDialogTextChangedSites = 'pricing-base-publish-dialog-text-changed-sites',
  PricingBasePublishDialogTextChangedSitesMultiple = 'pricing-base-publish-dialog-text-changed-sites-multiple',
  PricingBasePublishDialogTitle = 'pricing-base-publish-dialog-title',
  PricingBasePublishDialogTitlePublishing = 'pricing-base-publish-dialog-title-publishing',
  PricingBasePublishMessage = 'pricing-base-publish-message',
  PricingBasePreviewMessage = 'pricing-base-preview-message',
  PricingBaseRevertConfirmationTitle = 'pricing-base-revert-confirmation-title',
  PricingBaseRevertConfirmationMessage = 'pricing-base-revert-confirmation-message',
  PricingBaseSheetNameDialogTitle = 'pricing-base-sheet-name-dialog-title',
  PricingBaseSheetTitleField = 'pricing-base-sheet-title-field',
  PricingBaseSelectViewButtonTitle = 'pricing-base-select-view-button-title',
  PricingBaseSheetHasNoPricing = 'pricing-base-sheet-has-no-pricing',
  PricingBaseAttributeEmpty = 'pricing-base-attribute-empty',
  PricingBaseEmptyTitle = 'pricing-base-empty-title',
  PricingBaseEmptySubtitle = 'pricing-base-empty-subtitle',
  PricingBaseViewTypeGrid = 'pricing-base-view-type-grid',
  PricingBaseViewTypeList = 'pricing-base-view-type-list',
  PricingCalculationInclude = 'pricing-calculation-include',
  PricingCalculationLineItemDescription = 'pricing-calculation-line-item-description',
  PricingCalculationTaxable = 'pricing-calculation-taxable',
  PricingCalculationIncludeDealerDeposit = 'pricing-calculation-include-dealer-deposit',
  PricingCalculationCarportTaxation = 'pricing-calculation-carport-taxation',
  PricingCalculationShedTaxation = 'pricing-calculation-shed-taxation',
  PricingCarportExportSelect = 'pricing-carport-export-select',
  PricingCarportExportPriceSet = 'pricing-carport-export-price-set',
  PricingCarportExportPriceBook = 'pricing-carport-export-price-book',
  PricingCarportExportPreviewed = 'pricing-carport-export-previewed',
  PricingCarportExportPublished = 'pricing-carport-export-published',
  PricingCarportExportOriginal = 'pricing-carport-export-original',
  PricingCarportExportNoActiveOrDraft = 'pricing-carport-export-no-active-or-draft',
  PricingCategoriesBasePrice = 'pricing-categories-base-price',
  PricingCategoriesBasePriceTooltip = 'pricing-categories-base-price-tooltip',
  PricingCategoriesStructure = 'pricing-categories-structure',
  PricingCategoriesStructureTooltip = 'pricing-categories-structure-tooltip',
  PricingCategoriesDoorsWindows = 'pricing-categories-doors-windows',
  PricingCategoriesDoorsWindowsTooltip = 'pricing-categories-doors-windows-tooltip',
  PricingCategoriesAddOns = 'pricing-categories-add-ons',
  PricingCategoriesAddOnsTooltip = 'pricing-categories-add-ons-tooltip',
  PricingCategoriesServices = 'pricing-categories-services',
  PricingCategoriesServicesTooltip = 'pricing-categories-services-tooltip',
  PricingCategoriesNone = 'pricing-categories-none',
  PricingCategoriesNoneTooltip = 'pricing-categories-none-tooltip',
  PricingCategoriesCustomDiscountExpense = 'pricing-categories-custom-discount-expense',
  PricingCategoriesCustomDiscountExpenseTooltip = 'pricing-categories-custom-discount-expense-tooltip',
  PricingChangesSupplierPrefix = 'pricing-changes-supplier-prefix',
  PricingChangesTitle = 'pricing-changes-title',
  PricingComponentBuildingWidthPrefix = 'pricing-component-building-width-prefix',
  PricingComponentHeightPrefix = 'pricing-component-height-prefix',
  PricingComponentKeyLabel = 'pricing-component-key-label',
  PricingComponentNoCategoriesFound = 'pricing-component-no-categories-found',
  PricingComponentNoComponentsFound = 'pricing-component-no-components-found',
  PricingComponentNoPricingType = 'pricing-component-no-pricing-type',
  PricingComponentPreviewConfirmationTitle = 'pricing-component-preview-confirmation-title',
  PricingComponentPreviewMessage = 'pricing-component-preview-message',
  PricingComponentPriceDefaultPrice = 'pricing-component-price-default-price',
  PricingComponentPriceExpressionPrice = 'pricing-component-price-expression-price',
  PricingComponentPriceExpressionCalculation = 'pricing-component-price-expression-calculation',
  PricingComponentPriceExpressionHelp = 'pricing-component-price-expression-help',
  PricingComponentPriceVaries = 'pricing-component-price-varies',
  PricingComponentPricingTypeAmount = 'pricing-component-pricing-type-amount',
  PricingComponentPublishDialogText = 'pricing-component-publish-dialog-text',
  PricingComponentPublishDialogTitle = 'pricing-component-publish-dialog-title',
  PricingComponentPublishMessage = 'pricing-component-publish-message',
  PricingComponentRevertConfirmationMessage = 'pricing-component-revert-confirmation-message',
  PricingComponentRevertConfirmationTitle = 'pricing-component-revert-confirmation-title',
  PricingComponentWidthPrefix = 'pricing-component-width-prefix',
  PricingUnsavedChangesConfirmationMessage = 'pricing-unsaved-changes-confirmation-message',
  PricingChangesCalculation = 'pricing-changes-calculation',
  PricingChangesAmounts = 'pricing-changes-amounts',
  PricingComponentEditDialogTitle = 'pricing-component-edit-dialog-title',
  PricingComingSoon = 'pricing-coming-soon',
  PricingExportConfirmationDialogText = 'pricing-export-confirmation-dialog-text',
  PricingExportBasePriceTitle = 'pricing-export-base-price-title',
  PricingExportContent = 'pricing-export-content',
  PricingHeightRangeHeader = 'pricing-height-range-header',
  PricingOptionNoChange = 'pricing-option-no-change',
  PricingOptionSurcharge = 'pricing-option-surcharge',
  PricingOptionLineItem = 'pricing-option-line-item',
  PricingPercentChange = 'pricing-percent-change',
  PricingPriceRangeHeader = 'pricing-price-range-header',
  PricingSheetLabelHeader = 'pricing-sheet-label-header',
  PricingRangeTitle = 'pricing-range-title',
  PricingRangeSliderLabel = 'pricing-range-slider-label',
  PricingRoundToNearest = 'pricing-round-to-nearest',
  PricingRuleTitle = 'pricing-rule-title',
  PricingShedExportTitle = 'pricing-shed-export-title',
  PricingShedExportRows = 'pricing-shed-export-rows',
  PricingShedExportColumns = 'pricing-shed-export-columns',
  PricingShedExportPages = 'pricing-shed-export-pages',
  PricingShedExportFilterText = 'pricing-shed-export-filter-text',
  PricingSheetAttributeStyle = 'pricing-sheet-attribute-style',
  PricingSheetAttributeStyleOne = 'pricing-sheet-attribute-style_one',
  PricingSheetAttributeStyleOther = 'pricing-sheet-attribute-style_other',
  PricingSheetAttributeExpression = 'pricing-sheet-attribute-expression',
  PricingSheetAttributeExpressionOne = 'pricing-sheet-attribute-expression_one',
  PricingSheetAttributeExpressionOther = 'pricing-sheet-attribute-expression_other',
  PricingSheetAttributeSiding = 'pricing-sheet-attribute-siding',
  PricingSheetAttributeSidingOne = 'pricing-sheet-attribute-siding_one',
  PricingSheetAttributeSidingOther = 'pricing-sheet-attribute-siding_other',
  PricingSheetAttributeSidingColor = 'pricing-sheet-attribute-siding-color',
  PricingSheetAttributeSidingColorOne = 'pricing-sheet-attribute-siding-color_one',
  PricingSheetAttributeSidingColorOther = 'pricing-sheet-attribute-siding-color_other',
  PricingSheetAttributeRoof = 'pricing-sheet-attribute-roof',
  PricingSheetAttributeRoofOne = 'pricing-sheet-attribute-roof_one',
  PricingSheetAttributeRoofOther = 'pricing-sheet-attribute-roof_other',
  PricingSheetAttributeRoofColor = 'pricing-sheet-attribute-roof-color',
  PricingSheetAttributeRoofColorOne = 'pricing-sheet-attribute-roof-color_one',
  PricingSheetAttributeRoofColorOther = 'pricing-sheet-attribute-roof-color_other',
  PricingSheetAttributeHeight = 'pricing-sheet-attribute-height',
  PricingSheetAttributeHeightOne = 'pricing-sheet-attribute-height_one',
  PricingSheetAttributeHeightOther = 'pricing-sheet-attribute-height_other',
  PricingSheetAttributeTrimColor = 'pricing-sheet-attribute-trim-color',
  PricingSheetAttributeTrimColorOne = 'pricing-sheet-attribute-trim-color_one',
  PricingSheetAttributeTrimColorOther = 'pricing-sheet-attribute-trim-color_other',
  PricingSheetAttributeSection = 'pricing-sheet-attribute-section',
  PricingSheetAttributeSectionOne = 'pricing-sheet-attribute-section_one',
  PricingSheetAttributeSectionOther = 'pricing-sheet-attribute-section_other',
  PricingSheetAttributeGauge = 'pricing-sheet-attribute-gauge',
  PricingSheetAttributeGaugeOne = 'pricing-sheet-attribute-gauge_one',
  PricingSheetAttributeGaugeOther = 'pricing-sheet-attribute-gauge_other',
  PricingSheetAttributeLeg = 'pricing-sheet-attribute-leg',
  PricingSheetAttributeLegOne = 'pricing-sheet-attribute-leg_one',
  PricingSheetAttributeLegOther = 'pricing-sheet-attribute-leg_other',
  PricingSheetAttributeSnowLoad = 'pricing-sheet-attribute-snow-load',
  PricingSheetAttributeSnowLoadOne = 'pricing-sheet-attribute-snow-load_one',
  PricingSheetAttributeSnowLoadOther = 'pricing-sheet-attribute-snow-load_other',
  PricingSheetImperialUnit = 'pricing-imperial-unit',
  PricingSheetHeight = 'pricing-sheet-height',
  PricingSheetLength = 'pricing-sheet-length',
  PricingSheetMetricUnit = 'pricing-metric-unit',
  PricingSheetWidth = 'pricing-sheet-width',
  PricingSubtotalTitle = 'pricing-subtotal-title',
  PricingSubtotalRangeStart = 'pricing-subtotal-range-start',
  PricingSubtotalRangeEnd = 'pricing-subtotal-range-end',
  PricingSupplierSelector = 'pricing-supplier-selector',
  PricingSupplierSelectorConfirmationTitle = 'pricing-supplier-selector-confirmation-title',
  PricingSupplierSelectorConfirmationTurnOff = 'pricing-supplier-selector-confirmation-turn-off',
  PricingSurchargeConditionLength = 'pricing-surcharge-condition-length',
  PricingSurchargeConditionWidth = 'pricing-surcharge-condition-width',
  PricingSurchargeConditionSize = 'pricing-surcharge-condition-size',
  PricingSurchargeConditionStyle = 'pricing-surcharge-condition-style',
  PricingSurchargeConditionSiding = 'pricing-surcharge-condition-siding',
  PricingSurchargeConditionRoofType = 'pricing-surcharge-condition-roof-type',
  PricingSurchargeConditionGauge = 'pricing-surcharge-condition-gauge',
  PricingSurchargeConditionRegion = 'pricing-surcharge-condition-region',
  PricingSurchargeConditionState = 'pricing-surcharge-condition-state',
  PricingSurchargeConditionSubtotal = 'pricing-surcharge-condition-subtotal',
  PricingSurchargeConditionPriceSet = 'pricing-surcharge-condition-price-set',
  PricingSurchargePreviewMessage = 'pricing-surcharge-preview-message',
  PricingSurchargePublishMessage = 'pricing-surcharge-publish-message',
  PricingSurchargePublishDialogTitle = 'pricing-surcharge-publish-dialog-title',
  PricingSurchargePublishDialogText = 'pricing-surcharge-publish-dialog-text',
  PricingTextRevertConfirmationTitle = 'pricing-text-revert-confirmation-title',
  PricingTextRevertConfirmationBody = 'pricing-text-revert-confirmation-body',
  PricingTextPublishConfirmationTitle = 'pricing-text-publish-confirmation-title',
  PricingTextPublishConfirmationBody = 'pricing-text-publish-confirmation-body',
  PricingTextPreviewInstructions = 'pricing-text-preview-instructions',
  PricingTextPublishInstructions = 'pricing-text-publish-instructions',
  PricingSurchargePreviewConfirmationTitle = 'pricing-surcharge-preview-confirmation-title',
  PricingCalculationField = 'pricing-calculation-field',
  PricingVaryByRegion = 'pricing-vary-by-region',
  PricingTypeSurcharge = 'pricing-type-surcharge',
  PricingTypeChange = 'pricing-type-change',
  ProductionEnvironment = 'production-environment',
  ProfileProfileTab = 'profile-profile-tab',
  ProfilePreferencesTab = 'profile-preferences-tab',
  ProfilePasswordTab = 'profile-password-tab',
  ProfilePhoneNumber = 'profile-phone-number',
  ProfilePreferencesTheme = 'profile-preferences-theme',
  ProfilePreferencesThemePreviewValue = 'profile-preferences-theme-preview-value',
  ProfilePreferencesMinimap = 'profile-preferences-minimap',
  ProfileUpdateProfile = 'profile-update-profile',
  ProfileUpdatePassword = 'profile-update-password',
  ProfileUpdatePreferences = 'profile-update-preferences',
  Required = 'required',
  ReplaceAllButton = 'replace-all-button',
  ReplaceOneButton = 'replace-one-button',
  RevertButton = 'revert-button',
  RollbackButton = 'rollback-button',
  ViewButton = 'view-button',
  SceneEnvironment = 'scene-environment',
  SceneEnvironmentSelector = 'scene-environment-selector',
  SceneEnvironmentAllowUserChange = 'scene-environment-allow-user-change',
  SceneEnvironmentTabShedView = 'scene-environment-tab-shedview',
  SceneEnvironmentTabCarportView = 'scene-environment-tab-carportview',
  SceneEnvironmentConfirmationDialogDescription = 'scene-environment-confirmation-dialog-description',
  SceneEnvironmentFieldKey = 'scene-environment-field-key',
  SceneEnvironmentFieldLabel = 'scene-environment-field-label',
  SceneEnvironmentFieldFileUrl = 'scene-environment-field-file-url',
  SceneEnvironmentFieldPreviewUrl = 'scene-environment-field-preview-url',
  SceneEnvironmentFieldMaxCameraDistanceMultiplier = 'scene-environment-field-max-camera-distance-multiplier',
  SceneEnvironmentFieldMaxDiagonalBuildingLength = 'scene-environment-field-max-diagonal-building-length',
  SceneEnvironmentFileTooLarge = 'scene-environment-file-too-large',
  SceneEnvironmentFileInvalidType = 'scene-environment-file-invalid-type',
  SceneEnvironmentFileUnknownError = 'scene-environment-file-unknown-error',
  SceneEnvironmentFileDrop = 'scene-environment-file-drop',
  SearchInputPlaceholder = 'search-input-placeholder',
  SearchTypeCurrentSelection = 'search-type-current-selection',
  SearchTypeCurrentTable = 'search-type-current-table',
  SearchTypeAllTables = 'search-type-all-tables',
  SearchTypeAllVendorsCurrentTable = 'search-type-all-vendors-current-table',
  SearchTypeAllVendorsAllTables = 'search-type-all-vendors-all-tables',
  SettingsOptionsShowFormulas = 'settings-options-show-formulas',
  UtilityOptionsKeyboardShortcuts = 'utility-options-keyboard-shortcuts',
  UtilityOptionsKeyboardShortcutsLink = 'utility-options-keyboard-shortcuts-link',
  SettingsTabI18n = 'settings-tab-i18n',
  SettingsTabNotifications = 'settings-tab-notifications',
  SettingsTabSceneEnvironment = 'settings-tab-scene-environment',
  SetupPaymentIntegrationFailedDefaultMessage = 'setup-payment-integration-failed-default-message',
  SetupPaymentIntegrationFailedSubtitle = 'setup-payment-integration-failed-subtitle',
  SetupPaymentIntegrationFailedTitle = 'setup-payment-integration-failed-title',
  SetupPaymentIntegrationFixProblems = 'setup-payment-integration-fix-problems',
  SetupPaymentIntegrationGetStarted = 'setup-payment-integration-get-started',
  SetupPaymentIntegrationLearnMoreLinkText = 'setup-payment-integration-learn-more-link-text',
  SetupPaymentIntegrationLoadingContent = 'setup-payment-integration-failed-loading-content',
  SetupPaymentIntegrationLoadingTitle = 'setup-payment-integration-failed-loading-title',
  SetupPaymentIntegrationPendingDefaultMessage = 'setup-payment-integration-pending-default-message',
  SetupPaymentIntegrationPendingSubtitle = 'setup-payment-integration-pending-subtitle',
  SetupPaymentIntegrationPendingTitle = 'setup-payment-integration-pending-title',
  SetupPaymentIntegrationReachMoreCustomersSubtitle = 'setup-payment-integration-reach-more-customer-subtitle',
  SetupPaymentIntegrationReachMoreCustomersTitle = 'setup-payment-integration-reach-more-customer-title',
  SetupPaymentIntegrationReachMoreCustomersLink = 'setup-payment-integration-reach-more-customer-link',
  SetupPaymentIntegrationSecureRiableSubtitle = 'setup-payment-integration-secure-riable-subtitle',
  SetupPaymentIntegrationSecureRiableTitle = 'setup-payment-integration-secure-riable-title',
  SetupPaymentIntegrationSecureRiableLink = 'setup-payment-integration-secure-riable-link',
  SetupPaymentIntegrationSimplifyShoppingSubtitle = 'setup-payment-integration-simplify-shopping-subtitle',
  SetupPaymentIntegrationSimplifyShoppingTitle = 'setup-payment-integration-simplify-shopping-title',
  SetupPaymentIntegrationSimplifyShoppingLink = 'setup-payment-integration-simplify-shopping-link',
  SetupPaymentIntegrationStatus = 'setup-payment-integration-status',
  SetupPaymentIntegrationStartAcceptingOrder = 'setup-payment-integration-start-accepting-order',
  SetupPaymentIntegrationSubtitle = 'setup-payment-integration-subtitle',
  SetupPaymentIntegrationTitle = 'setup-payment-integration-title',
  SetupPaymentIntegrationViewDetails = 'setup-payment-integration-view-details',
  SetupPaymentIntegrationWhatYouWillNeed = 'setup-payment-integration-what-you-will-need',
  SetupPaymentIntegrationWhatYouWillNeedDocuments = 'setup-payment-integration-what-you-will-need-documents',
  SetupPaymentIntegrationWhatYouWillNeedBank = 'setup-payment-integration-what-you-will-need-bank',
  ShedPricingCategoriesSurchargeBasePrice = 'shed-pricing-categories-surcharge-base-price',
  ShedPricingCategoriesLineItemBasePrice = 'shed-pricing-categories-line-item-base-price',
  ShedPricingCategoriesSurchargeSubtotal = 'shed-pricing-categories-surcharge-subtotal',
  ShedPricingCategoriesLineItemSubtotal = 'shed-pricing-categories-line-item-subtotal',
  SignUpSubmitButton = 'sign-up-submit-button',
  SignInAlreadyHaveLoginMessage = 'sign-in-already-have-login-message',
  SignInButton = 'sign-in-button',
  SignInMessage = 'sign-in-message',
  SignInSSOSignInLink = 'sign-in-sso-sign-in-link',
  SingleSignOnOrganizationCodePlaceholder = 'single-sign-on-organization-code-placeholder',
  SingleSignOnOrganizationCodeTitle = 'single-sign-on-organization-code-title',
  SingleSignOnSubmitButton = 'single-sign-on-submit-button',
  SingleSignOnUsernamePasswordButton = 'single-sign-on-username-password-button',
  SitesAdvancedAuthenticationProviderField = 'sites-advanced-authentication-provider-field',
  SitesBasicInformationAddressInputField = 'sites-basic-information-address-input-field',
  SitesBasicInformationCityInputField = 'sites-basic-information-city-input-field',
  SitesBasicInformationFaxNumberInputField = 'sites-basic-information-fax-number-input-field',
  SitesBasicInformationHomeLinkInputField = 'sites-basic-information-home-link-input-field',
  SitesBasicInformationNameInputField = 'sites-basic-information-name-input-field',
  SitesBasicInformationPhoneNumberInputField = 'sites-basic-information-phone-number-input-field',
  SitesBasicInformationStateInputField = 'sites-basic-information-state-input-field',
  SitesBasicInformationZipCodeInputField = 'sites-basic-information-zip-code-input-field',
  SitesBetaModeField = 'sites-beta-mode-field',
  SitesBetaModeAlways = 'sites-beta-mode-always',
  SitesBetaModeNever = 'sites-beta-mode-never',
  SitesBetaModeLoggedInWithSalesTools = 'sites-beta-mode-logged-in-with-sales-tools',
  SitesCaptureInfoField = 'sites-capture-info-field',
  SitesCaptureInfoRequirePhoneField = 'sites-capture-info-require-phone-field',
  SitesColorsContactBarColor = 'sites-colors-contact-bar-color',
  SitesColorsContactBarTextColor = 'sites-colors-contact-bar-text-color',
  SitesColorsLogoBarColor = 'sites-colors-logo-bar-color',
  SitesColorsLogoBarTextColor = 'sites-colors-logo-bar-text-color',
  SitesColorsSelectedTextColor = 'sites-colors-selected-text-color',
  SitesColorsYourEstimateTabColor = 'sites-colors-your-estimate-tab-color',
  SitesColorsYourEstimateTextColor = 'sites-colors-your-estimate-text-color',
  SitesDisableSharing = 'sites-disable-sharing',
  SitesDisableAR = 'sites-disable-ar',
  SitesEmailContactEmailField = 'sites-email-contact-email-field',
  SitesEmailDomainManagementButton = 'sites-email-domain-management-button',
  SitesEmailDomainNotVerified = 'sites-email-domain-not-verified',
  SitesEmailDomainIconLoadingHoverText = 'sites-email-domain-icon-loading-hover-text',
  SitesEmailDomainIconUnverifiableHoverText = 'sites-email-domain-icon-unverifiable-hover-text',
  SitesEmailDomainIconUnverifiableCustomSmtpHostHoverText = 'sites-email-domain-icon-unverifiable-custom-dmtp-host-hover-text',
  SitesEmailDomainIconUnverifiedHoverText = 'sites-email-domain-icon-unverified-hover-text',
  SitesEmailDomainRecordNoteDmarc = 'sites-email-domain-record-note-dmarc',
  SitesEmailDomainIconVerifiedHoverText = 'sites-email-domain-icon-verified-hover-text',
  SitesEmailDomainLoadingProgress = 'sites-email-domain-loading-progress',
  SitesEmailDomainVerifyButton = 'sites-email-domain-verify-button',
  SitesEmailDomainDialogTitle = 'sites-email-domain-dialog-title',
  SitesEmailDomainDialogContent = 'sites-email-domain-dialog-content',
  SitesEmailDomainDialogContentVerified = 'sites-email-domain-dialog-content-verified',
  SitesEmailDomainDialogLearnMoreLink = 'sites-email-domain-dialog-learn-more-link',
  SitesEmailDomainDialogLearnMore = 'sites-email-domain-dialog-learn-more',
  SitesEmailDomainDialogVerifyText = 'sites-email-domain-dialog-verify-text',
  SitesEmailDomainVerifying = 'sites-email-domain-verifying',
  SitesEmailDomainDomainVerificationFailed = 'sites-email-domain-domain-verification-failed',
  SitesEmailDomainDomainVerificationSucceeded = 'sites-email-domain-domain-verification-succeeded',
  SitesEmailEmailCopySameCheckbox = 'sites-email-email-copy-same-checkbox',
  SitesEmailEmailCopySameField = 'sites-email-email-copy-same-field',
  SitesEmailEmailReplyToSameCheckbox = 'sites-email-email-reply-to-same-checkbox',
  SitesEmailEmailReplyToSameField = 'sites-email-email-reply-to-same-field',
  SitesEmailFormatSummary = 'sites-email-format-summary',
  SitesEmailFormatInvoice = 'sites-email-format-invoice',
  SitesEmailCheckoutEmailFormatField = 'sites-email-checkout-email-format-field',
  SitesEmailQuoteEmailFormatField = 'sites-email-quote-email-format-field',
  SitesEmailSaveEmailFormatField = 'sites-email-save-email-format-field',
  SitesEmailSendEmailsToField = 'sites-email-send-emails-to-field',
  SitesEmailPrintEmailFormatField = 'sites-email-print-email-format-field',
  SitesEnableDesignAutoSave = 'sites-enable-design-auto-save',
  SitesFloorplanEmailFloorPlanField = 'sites-floorplan-email-floor-plan-field',
  SitesFloorplanEmailFloorplanImageScaleField = 'sites-floorplan-email-floorplan-image-scale-field',
  SitesFloorplanFloorplanLabelingMethodField = 'sites-floorplan-floorplan-labeling-method-field',
  SitesFloorplanFloorplanLabelingMethodFieldLabelsInLegend = 'sites-floorplan-floorplan-labeling-method-field-labels-in-legend',
  SitesFloorplanFloorplanLabelingMethodFieldLabelsOnDiagram = 'sites-floorplan-floorplan-labeling-method-field-labels-on-diagram',
  SitesFloorplanFloorplanGridStyleField = 'sites-floorplan-floorplan-grid-style-field',
  SitesFloorplanFloorplanGridStyleFieldLabelsSolid = 'sites-floorplan-floorplan-grid-style-field-solid',
  SitesFloorplanFloorplanGridStyleFieldLabelsDotted = 'sites-floorplan-floorplan-grid-style-field-dotted',
  SitesFloorplanFloorplanGridStyleFieldLabelsNone = 'sites-floorplan-floorplan-grid-style-field-none',
  SitesFloorplanFloorplanMeasurementArrowheadStyleField = 'sites-floorplan-floorplan-measurement-arrowhead-style-field',
  SitesFloorplanFloorplanMeasurementArrowheadStyleFieldLabelsOblique = 'sites-floorplan-floorplan-measurement-arrowhead-style-field-oblique',
  SitesFloorplanFloorplanMeasurementArrowheadStyleFieldLabelsOpenArrow = 'sites-floorplan-floorplan-measurement-arrowhead-style-field-open-arrow',
  SitesFloorplanFloorplanWallThicknessField = 'sites-floorplan-floorplan-wall-thickness-field',
  SitesFloorplanFloorplanTextSizeField = 'sites-floorplan-floorplan-text-size-field',
  SitesFloorplanFloorplanHeightField = 'sites-floorplan-floorplan-height-field',
  SitesFloorplanFloorplanWidthField = 'sites-floorplan-floorplan-width-field',
  SitesFloorplanFloorplanShowSideTextsField = 'sites-floorplan-floorplan-show-side-texts-field',
  SitesFloorplanFloorplanShowFlowerboxField = 'sites-floorplan-floorplan-show-flowerbox-field',
  SitesFloorplanShowPorchBoardDirectionField = 'sites-floorplan-floorplan-show-porch-board-direction-field',
  SitesFloorplanFloorplanComponentStyleField = 'sites-floorplan-floorplan-component-style-field',
  SitesFloorplanFloorplanComponentStyleFieldLabelsClassic = 'sites-floorplan-floorplan-component-style-field-labels-classic',
  SitesFloorplanFloorplanComponentStyleFieldlabelsVersion2 = 'sites-floorplan-floorplan-component-style-field-labels-version-2',
  SitesFloorplanFrontDirectionField = 'sites-floorplan-front-direction-field',
  SitesFloorplanFrontDirectionFieldBottom = 'sites-floorplan-front-direction-field-bottom',
  SitesFloorplanFrontDirectionFieldLeft = 'sites-floorplan-front-direction-field-left',
  SitesFloorplanUseFloorPlanGridDimensionsField = 'sites-floorplan-use-floor-plan-grid-dimensions-field',
  SitesFloorplanUseFloorPlanGridDimensionsLegendField = 'sites-floorplan-use-floor-plan-grid-dimensions-legend-field',
  SitesFloorplanUseFloorplanFullPageField = 'sites-floorplan-use-floorplan-full-page-field',
  SitesHideCenterComponentsButton = 'sites-hide-center-components-button',
  SitesInformationPanelShowInfoPanel = 'sites-information-panel-show-info-panel',
  SitesInformationPanelTitle = 'sites-information-panel-title',
  SitesInformationPanelSubtitle = 'sites-information-panel-subtitle',
  SitesInformationPanelMessage = 'sites-information-panel-message',
  SitesLayoutClassic = 'sites-layout-classic',
  SitesLayoutModern = 'sites-layout-modern',
  SitesLayoutMobile = 'sites-layout-mobile',
  EnableClientManagedLeadsForm = 'enable-client-managed-leads-form',
  SitesLeadsEnableEmailPopup = 'sites-leads-enable-email-popup',
  SitesLeadsRequireForDesign = 'sites-leads-require-for-design',
  SitesLeadsPopupDelay = 'sites-leads-popup-delay',
  SitesLeadsPopupTitle = 'sites-leads-popup-title',
  SitesLeadsPopupMessage = 'sites-leads-popup-message',
  SitesLogosConfiguratorEmailLogoField = 'sites-logos-configurator-email-logo-field',
  SitesLogosConfiguratorLogoField = 'sites-logos-configurator-logo-field',
  SitesLogosConfiguratorLogoShowOnMobile = 'sites-logos-configurator-logo-show-on-mobile',
  SitesLogosConfiguratorWatermarkField = 'sites-logos-configurator-watermark-field',
  SitesLogosConfiguratorWatermarkStyleCenter = 'sites-logos-configurator-watermark-style-center',
  SitesLogosConfiguratorWatermarkStyleCenterNotRotated = 'sites-logos-configurator-watermark-style-center-not-rotated',
  SitesLogosConfiguratorWatermarkStyleCenterText = 'sites-logos-configurator-watermark-style-center-text',
  SitesLogosConfiguratorWatermarkStyleCenterTextNotRotated = 'sites-logos-configurator-watermark-style-center-text-not-rotated',
  SitesLogosConfiguratorWatermarkStyleField = 'sites-logos-configurator-watermark-style-field',
  SitesLogosConfiguratorWatermarkStyleGroundEdges = 'sites-logos-configurator-watermark-style-ground-edges',
  SitesLogosConfiguratorWatermarkStyleLeft = 'sites-logos-configurator-watermark-style-left',
  SitesLogosConfiguratorWatermarkStyleNone = 'sites-logos-configurator-watermark-style-none',
  SitesLogosConfiguratorWatermarkStyleRight = 'sites-logos-configurator-watermark-style-right',
  SitesLogosConfiguratorWatermarkTextField = 'sites-logos-configurator-watermark-text-field',
  SitesLogosConfiguratorWatermarkShowBelowEstimate = 'sites-logos-configurator-watermark-show-below-estimate',
  SitesLogosDeleteLogoConfirmMessage = 'sites-logos-delete-logo-confirm-message',
  SitesLogosDeleteLogoConfirmTitle = 'sites-logos-delete-logo-confirm-title',
  SitesLogosDeletePublishImageWarning = 'sites-logos-delete-publish-image-warning',
  SitesLogosDeleteWatermarkConfirmMessage = 'sites-logos-delete-watermark-confirm-message',
  SitesLogosDeleteWatermarkConfirmTitle = 'sites-logos-delete-watermark-confirm-title',
  SitesLogosNoneSelected = 'sites-logos-none-selected',
  SitesLogosProductionWarning = 'sites-logos-production-warning',
  SitesLogosUploadImageOption = 'sites-logos-upload-image-option',
  SitesPriceRangePercentage = 'sites-price-range-percentage',
  SitesPricingVisibility = 'sites-pricing-visibility',
  SitesPricingVisibilityStaging = 'sites-pricing-visibility-staging',
  SitesPricingClientManagedPricing = 'sites-pricing-client-managed-pricing',
  SitesPricingClientUpdates = 'sites-pricing-client-updates',
  SitesPromotionalBannerTitle = 'sites-promotional-banner-title',
  SitesPromotionalBannerDescription = 'sites-promotional-banner-description',
  SitesPromotionalBannerCustom = 'sites-promotional-banner-custom',
  SitesPromotionalBannerStandard = 'sites-promotional-banner-standard',
  SitesPromotionalBannerDesktopMessage = 'sites-promotional-banner-desktop-message',
  SitesPromotionalBannerDefaultDesktopMessage = 'sites-promotional-banner-default-desktop-message',
  SitesPromotionalBannerMobileMessage = 'sites-promotional-banner-mobile-message',
  SitesPromotionalBannerDefaultMobileMessage = 'sites-promotional-banner-default-mobile-message',
  SitesPromotionalBannerAddButton = 'sites-promotional-banner-add-button',
  SitesPromotionalBannerRemoveButton = 'sites-promotional-banner-remove-button',
  SitesPromotionalBannerRotatingMessages = 'sites-promotional-banner-rotating-messages',
  SitesPromotionalMaxMobileLengthWarning = 'sites-promotional-max-mobile-length-warning',
  SitesDeliveryMessageSectionTitle = 'sites-delivery-message-section-title',
  SitesDeliveryMessageSectionDescription = 'sites-delivery-message-section-description',
  SitesDeliveryMessageStandard = 'sites-delivery-message-standard',
  SitesDeliveryMessageCustom = 'sites-delivery-message-custom',
  SitesDeliveryMessageTitle = 'sites-delivery-message-title',
  SitesInstallationMessageSectionTitle = 'sites-installation-message-section-title',
  SitesInstallationMessageSectionDescription = 'sites-installation-message-section-description',
  SitesInstallationMessageStandard = 'sites-installation-message-standard',
  SitesInstallationMessageCustom = 'sites-installation-message-custom',
  SitesInstallationMessageTitle = 'sites-installation-message-title',
  SitesLeadGenerationSectionTitle = 'sites-lead-generation-section-title',
  SitesLeadGenerationSectionDescription = 'sites-lead-generation-section-description',
  SitesProSubscriptionAlert = 'sites-pro-subscription-alert',
  SitesSaveAndCheckoutLayoutField = 'sites-save-and-checkout-layout-field',
  SitesSectionAdvanced = 'sites-section-advanced',
  SitesSectionBasicInformation = 'sites-section-basic-information',
  SitesSectionColorsAndScene = 'sites-section-colors-and-scene',
  SitesSectionConfiguratorSettings = 'sites-section-configurator-settings',
  SitesSectionEmailAndContact = 'sites-section-email-and-contact',
  SitesSectionFloorplans = 'sites-section-floorplans',
  SitesSectionInformationPanel = 'sites-section-information-panel',
  SitesSectionLeadsPopupPanel = 'sites-section-leads-popup-panel',
  SitesSectionLogos = 'sites-section-logos',
  SitesSectionPricingInformation = 'sites-section-pricing-information',
  SitesSectionSaveAndCheckout = 'sites-section-save-and-checkout',
  SitesSendEmailToNone = 'sites-send-email-to-none',
  SitesSendEmailToDefault = 'sites-send-email-to-default',
  SitesSendEmailToSupplierAndDealer = 'sites-send-email-to-supplier-and-dealer',
  SitesSendEmailToDealerAndBuyerOnly = 'sites-send-email-to-dealer-and-buyer-only',
  SiteTextSectionTextOverrides = 'site-text-section-text-overrides',
  SiteTextSectionAvailableText = 'site-text-section-available-text',
  SiteTextInformation = 'site-text-information',
  SiteTextRevertConfirmationTitle = 'site-text-revert-confirmation-title',
  SiteTextRevertConfirmationBody = 'site-text-revert-confirmation-body',
  SiteTextPublishConfirmationTitle = 'site-text-publish-confirmation-title',
  SiteTextPublishConfirmationBody = 'site-text-publish-confirmation-body',
  SiteTextPreviewInstructions = 'site-text-preview-instructions',
  SiteTextPublishInstructions = 'site-text-publish-instructions',
  SiteTextClientLanguageSelector = 'site-text-client-language-selector',
  SitesUnsavedChangesConfirmationMessage = 'sites-unsaved-changes-confirmation-message',
  SizeBasedTab = 'size-based-tab',
  SomeHtmlTagsAllowed = 'some-html-tags-allowed',
  StagingEnvironment = 'staging-environment',
  StatsTotalMembers = 'stats-total-members',
  StatsActiveMembers = 'stats-active-members',
  StatsInactiveMembers = 'stats-inactive-members',
  StatusBarAddFromClipboard = 'status-bar-add-from-clipboard',
  StatusBarAddMultipleRows = 'status-bar-add-multiple-rows',
  StatusBarRemoveFilter = 'status-bar-remove-filter',
  StatusBarRowOption = 'status-bar-row-option',
  SubmitStatusDraft = 'submit-status-draft',
  SubmitStatusPopup = 'submit-status-popup',
  SubmitStatusSave = 'submit-status-save',
  SubmitStatusSaveToList = 'submit-status-save-to-list',
  SubmitStatusQuote = 'submit-status-quote',
  SubmitStatusCheckout = 'submit-status-checkout',
  SubmitStatusInvoice = 'submit-status-invoice',
  SubmitStatusUnknown = 'submit-status-unknown',
  TableHeaderBuildingSize = 'table-header-building-size',
  TableHeaderBuildingStyle = 'table-header-building-style',
  TableHeaderCity = 'table-header-city',
  TableHeaderContactEmail = 'table-header-contact-email',
  TableHeaderCreated = 'table-header-created',
  TableHeaderDealer = 'table-header-dealer',
  TableHeaderDealerNetwork = 'table-header-dealer-network',
  TableHeaderDealers = 'table-header-dealers',
  TableHeaderDeliveryZip = 'table-header-delivery-zip',
  TableHeaderDesign = 'table-header-design',
  TableHeaderEmail = 'table-header-email',
  TableHeaderEstimate = 'table-header-estimate',
  TableHeaderGroupName = 'table-header-group-name',
  TableHeaderKey = 'table-header-key',
  TableHeaderName = 'table-header-name',
  TableHeaderOriginalText = 'table-header-original-text',
  TableHeaderOverrideText = 'table-header-override-text',
  TableHeaderOwner = 'table-header-owner',
  TableHeaderPermissions = 'table-header-permissions',
  TableHeaderPhone = 'table-header-phone',
  TableHeaderPricing = 'table-header-pricing',
  TableHeaderProSubscription = 'table-header-pro-subscription',
  TableHeaderReferenceNumber = 'table-header-reference-number',
  TableHeaderSalesTools = 'table-header-sales-tools',
  TableHeaderSite = 'table-header-site',
  TableHeaderSmartBuild = 'table-header-smartbuild',
  TableHeaderState = 'table-header-state',
  TableHeaderStatus = 'table-header-status',
  TableHeaderSubmitStatus = 'table-header-submit-status',
  TableHeaderTemplate = 'table-header-template',
  TableHeaderType = 'table-header-type',
  TableHeaderValue = 'table-header-value',
  TableHeaderVendorKey = 'table-header-vendor-key',
  TableNoData = 'table-no-data',
  TableSortMenuAscendingText = 'table-sort-menu-ascending-text',
  TableSortMenuDescendingText = 'table-sort-menu-descending-text',
  ToastNoClientDataAvailable = 'toast-no-client-data-available',
  TopBarBreadcrumbsForgotPassword = 'top-bar-breadcrumbs-forgot-password',
  TopBarBreadcrumbsPortal = 'top-bar-breadcrumbs-portal',
  TopBarBreadcrumbsProfile = 'top-bar-breadcrumbs-profile',
  TourBackButton = 'tour-back-button',
  TourNextButton = 'tour-next-button',
  UnsavedChangesConfirmationConfirmButton = 'unsaved-changes-confirmation-confirm-button',
  UnsavedChangesConfirmationTitle = 'unsaved-changes-confirmation-title',
  UserDialogTitle = 'user-dialog-title',
  UserDialogTabSingleMember = 'user-dialog-tab-single-member',
  UserDialogTabBulkImport = 'user-dialog-tab-bulk-import',
  UserDialogBulkMemberSteps = 'user-dialog-bulk-member-steps',
  UserDialogUserTypeMember = 'user-dialog-user-type-member',
  UserDialogUserTypeUser = 'user-dialog-user-type-user',
  UserFormFieldDealers = 'user-form-field-dealers',
  UserFormLastLogin = 'user-form-last-login',
  UserFormNeverLoggedIn = 'user-form-never-logged-in',
  UserMenuItemMyProfile = 'user-menu-item-my-profile',
  UserMenuItemLanguage = 'user-menu-item-language',
  UserMenuItemLogOut = 'user-menu-item-log-out',
  UserMissingFromUserPool = 'user-missing-from-user-pool',
  UtilityOptionsVerifyQuotes = 'utility-options-verify-quotes',
  VerifiedQuotesDialogCompleteBodyAllMatch = 'verified-quotes-dialog-complete-all-match',
  VerifiedQuotesDialogCompleteBodySomeInvalid = 'verified-quotes-dialog-complete-some-invalid',
  VerifiedQuotesDialogCompleteBodySomeMismatch = 'verified-quotes-dialog-complete-some-mismatch',
  VerifiedQuotesDialogErrorBody = 'verified-quotes-dialog-error-body',
  VerifiedQuotesDialogErrorTitle = 'verified-quotes-dialog-error-title',
  VerifiedQuotesDialogInternalHelpLink = 'verified-quotes-dialog-internal-help-link',
  VerifiedQuotesDialogInProgressBody = 'verified-quotes-dialog-in-progress-body',
  VerifiedQuotesDialogInProgressTitle = 'verified-quotes-dialog-in-progress-title',
  VerifiedQuotesDialogNoQuotesBody = 'verified-quotes-dialog-no-quotes-body',
  VerifiedQuotesDialogTitle = 'verified-quotes-dialog-title',
  VerifiedQuotesVendorSelectTitle = 'verified-quotes-vendor-select-title',
  VerifiedQuotesVerify = 'verified-quotes-verify',
  VerifiedQuotesViewReport = 'verified-quotes-view-report',
  ViewLogsButton = 'view-logs-button',
  ViewSiteButton = 'view-site-button',
  ViewVendorSheetButton = 'view-vendor-sheet-button',
  ViewSupplierSheetButton = 'view-supplier-sheet-button',
  ViewSharedReferenceSheetButton = 'view-shared-reference-sheet-button',
  ViewCarportsReferenceSheetButton = 'view-carports-reference-sheet-button',
  ViewShedsReferenceSheetButton = 'view-sheds-reference-sheet-button',
  WallPosition = 'wall-position',
  WallPositionGableEnd = 'wall-position-gable-end',
  WallPositionSideWall = 'wall-position-side-wall',
  WhatsNew = 'whats-new',
  WhatsNewTabSalesView = 'whats-new-tab-sales-view',
  WhatsNewTabShedView = 'whats-new-tab-shed-view',
  WhatsNewTabCarportView = 'whats-new-tab-carport-view',
  WhatsNewReadOnlyWarning = 'whats-new-read-only-warning',
  WhatsNewPreview = 'whats-new-preview',
  WhatsNewDisplayStarting = 'whats-new-display-starting',
  WhatsNewFieldTitle = 'whats-new-field-title',
  WhatsNewFieldMessage = 'whats-new-field-message',
  WhatsNewConfirmationDialogDescription = 'whats-new-confirmation-dialog-description',
}
