import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import { CustomFloatingFilterProps } from 'ag-grid-react';
import { IFloatingFilterParams } from 'ag-grid-community';
import { DEFAULT_TEXT_FILTER_TYPE } from '../constants/ClientData';
import { useAppSelector } from '../hooks';

const useStyles = makeStyles(() => ({
  filterInput: {
    width: '100%',
    padding: '4px 0',
    '& .MuiOutlinedInput-root': {
      fontSize: '0.85rem',
    },
    '& .MuiOutlinedInput-input': {
      padding: '3px 0 3px 8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      paddingRight: '0',
    },
  },
  clearButton: {
    paddingRight: '4px',
    color: '#4286F4',
  },
  clearIcon: {
    fontSize: '1.1rem',
  },
}));

export const ClientDataTextColumnFilter: React.FC<IFloatingFilterParams & CustomFloatingFilterProps> = ({
  column,
  parentFilterInstance,
  currentParentModel,
}: IFloatingFilterParams & CustomFloatingFilterProps) => {
  const classes = useStyles();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { highlightedCell } = useAppSelector((state) => state?.clientData);
  const { type = DEFAULT_TEXT_FILTER_TYPE, filter: parentFilterValue } = currentParentModel() || {};

  const [filterValue, setFilterValue] = React.useState(parentFilterValue || '');

  useEffect(() => {
    setFilterValue(parentFilterValue || '');
  }, [parentFilterValue]);

  const onSetColumnFilter = (value: string) => {
    parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged(type, value);
      setFilterValue(value);
    });
  };

  const onClearColumnFilter = () => {
    parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged(['blank', 'notBlank'].includes(type) ? DEFAULT_TEXT_FILTER_TYPE : type, '');
      setFilterValue('');
    });
  };

  useEffect(() => {
    const highlightCol = highlightedCell?.colId === column.getColId();

    if (inputRef) {
      const element = inputRef.current?.parentElement?.parentElement;
      if (element) {
        if (highlightCol && !element.classList.contains('ag-column-hover')) element.classList.add('ag-column-hover');
        if (!highlightCol && element.classList.contains('ag-column-hover')) element.classList.remove('ag-column-hover');
      }
    }
  }, [highlightedCell, inputRef, column]);

  return (
    <TextField
      ref={inputRef}
      id="text-column-filter"
      value={filterValue}
      onChange={(input) => onSetColumnFilter(input.target.value)}
      className={classes.filterInput}
      disabled={['blank', 'notBlank'].includes(currentParentModel()?.type)}
      hiddenLabel
      size="small"
      slotProps={{
        input: {
          endAdornment: filterValue && (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear column filter"
                onClick={onClearColumnFilter}
                onMouseDown={onClearColumnFilter}
                edge="end"
                className={classes.clearButton}
              >
                <HighlightOff className={classes.clearIcon} />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
